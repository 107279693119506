import { Grid, Typography } from '@mui/material'
import FormikInput from '../../../components/formik-input'
import FormikSelect from '../../../components/formik-select'

const O2Saturation = () => {
  return (
    <>
      <Grid item={true} xs={12} container={true} alignItems="center" spacing={3}>
        <Grid item={true} xs={2}>
          <Typography variant="subtitle1">O2 Saturation</Typography>
        </Grid>

        <Grid item={true} xs={2}>
          <Typography variant="subtitle1">drops below</Typography>
        </Grid>

        <Grid item={true} xs={2}>
          <FormikInput name="o2saturation.dropsBelowPercentage" inputLabel="%" size="small" type="number" />
        </Grid>
      </Grid>

      <Grid item={true} xs={12} container={true} alignItems="center" spacing={3}>
        <Grid item={true} xs={2}>
          <Typography variant="subtitle1">O2 Saturation</Typography>
        </Grid>

        <Grid item={true} xs={2}>
          <Typography variant="subtitle1">falls in between</Typography>
        </Grid>

        <Grid item={true} xs={1}>
          <FormikInput name="o2saturation.fallsBetweenValue1" inputLabel="Value 1" size="small" type="number" />
        </Grid>

        <Grid item={true} xs={1}>
          <Typography variant="subtitle1">and</Typography>
        </Grid>

        <Grid item={true} xs={1}>
          <FormikInput name="o2saturation.fallsBetweenValue2" inputLabel="Value 2" size="small" type="number" />
        </Grid>

        <Grid item={true} xs={1}>
          <Typography variant="subtitle1">during</Typography>
        </Grid>

        <Grid item={true} xs={3}>
          <FormikSelect name="o2saturation.fallsBetweenDuration" dropdownItems={['night', 'day']} sx={{ marginTop: '1px' }} />
        </Grid>
      </Grid>

      <Grid item={true} xs={12} container={true} alignItems="center" spacing={3}>
        <Grid item={true} xs={2}>
          <Typography variant="subtitle1">O2 Saturation</Typography>
        </Grid>

        <Grid item={true} xs={2}>
          <Typography variant="subtitle1">decrease by</Typography>
        </Grid>

        <Grid item={true} xs={2}>
          <FormikInput name="o2saturation.decreaseByPercentage" inputLabel="%" size="small" type="number" />
        </Grid>

        <Grid item={true} xs={2}>
          <Typography variant="subtitle1">from the</Typography>
        </Grid>

        <Grid item={true} xs={3}>
          <FormikSelect name="o2saturation.decreaseByPercentageDuration" dropdownItems={['monthly average', 'weekly average']} sx={{ marginTop: '1px' }} />
        </Grid>
      </Grid>
    </>
  )
}

export default O2Saturation
