import Header from './components/header'
import Sidenav from './components/sidenav'
import { Navigate, Route, Routes, useNavigate, useLocation} from 'react-router-dom'
import User from './routes/mommy-details'
import Notifications from './routes/notifications'
import Box from '@mui/material/Box'
import { HEADER_HEIGHT } from './helpers/constants'
import { useContext, useState, useEffect } from 'react'
import { SidenavContext } from './providers/sidenav'
import { MainContent } from './App.styled'
import { AuthenticationContext } from './providers/authentication'
import Login from './routes/login'
import ForgotPassword from './routes/forgot-password'
import Snapshot from './routes/snapshot'
import { useTheme, useMediaQuery } from '@mui/material'

const App = () => {
  const [isSidenavOpen, setIsSidenavOpen] = useState<boolean>(false)
  const { isLoggedIn } = useContext(AuthenticationContext)

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  // const navigate = useNavigate()
  const location = useLocation();

  // Save the current path to local storage on every navigation
  useEffect(() => {
    localStorage.setItem('lastVisitedPage', location.pathname);
  }, [location]);

  // // Redirect to the last visited page on page load
  // useEffect(() => {
  //   const lastVisitedPage = localStorage.getItem('lastVisitedPage');
    
  //   if (lastVisitedPage) {
  //     navigate(lastVisitedPage)
  //   }
  // }, [navigate]);

  useEffect(() => {
    setIsSidenavOpen(!matches)
  }, [matches])

  return isLoggedIn === false ? (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
    </Routes>
  ) : (
    <SidenavContext.Provider
      value={{
        isSidenavOpen: isSidenavOpen,
        toggleSidenav: () => setIsSidenavOpen(!isSidenavOpen),
      }}
    >
      <Header />

      <Box
        sx={{
          marginTop: HEADER_HEIGHT,
          flex: '1 1 auto',
          display: 'flex',
          height: 'inherit',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        <Sidenav />

        <MainContent open={isSidenavOpen}>
          <Routes>
            <Route path="/snapshot" element={<Snapshot />}></Route>
            <Route path="/mommy-details" element={<User />}></Route>
            <Route path="/notifications" element={<Notifications />}></Route>
          </Routes>
        </MainContent>
      </Box>
    </SidenavContext.Provider>
  )
}

export default App
