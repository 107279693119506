import { Grid, Typography } from '@mui/material'
import FormikSelect from '../../../components/formik-select'

const SleepData = () => {
  return (
    <Grid item={true} xs={12} container={true} alignItems="center" spacing={3}>
      <Grid item={true} xs={2}>
        <Typography variant="subtitle1">Sleep Data</Typography>
      </Grid>

      <Grid item={true} xs={2}>
        <Typography variant="subtitle1">is</Typography>
      </Grid>

      <Grid item={true} xs={2}>
        <FormikSelect name="sleepData.sleepDataPresense" dropdownItems={['missing', 'present']} sx={{ marginTop: '1px' }} />
      </Grid>
    </Grid>
  )
}

export default SleepData
