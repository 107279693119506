import { Grid, Typography } from '@mui/material'
import FormikInput from '../../../components/formik-input'
import FormikSelect from '../../../components/formik-select'

const Stress = () => {
  return (
    <Grid item={true} xs={12} container={true} alignItems="center" spacing={3}>
      <Grid item={true} xs={2}>
        <Typography variant="subtitle1">Stress</Typography>
      </Grid>

      <Grid item={true} xs={2}>
        <Typography variant="subtitle1">is</Typography>
      </Grid>

      <Grid item={true} xs={2}>
        <FormikInput name="stress.pointsAbove" inputLabel="Points" size="small" type="number" />
      </Grid>

      <Grid item={true} xs={2}>
        <Typography variant="subtitle1">above the</Typography>
      </Grid>

      <Grid item={true} xs={3}>
        <FormikSelect name="stress.stressDuration" dropdownItems={['monthly average', 'weekly average']} sx={{ marginTop: '7px' }} />
      </Grid>
    </Grid>
  )
}

export default Stress
