import { ReactElement, useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import axios from 'axios'
import MUIDataTable from 'mui-datatables'
import Checkbox from '@mui/material/Checkbox'
import { CacheProvider } from '@emotion/react'
import { Button, createTheme, IconButton, ThemeProvider } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import createCache from '@emotion/cache'
import { useContext } from 'react'
import { AuthenticationContext } from '../../providers/authentication'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import DynamicSearchTable from '../../components/sandbox/dynamic-search-table.tsx'
import { getTodaysDateFormatted } from '../../helpers/misc-functions'


interface IUser {
  nickname: ReactElement | string
  nickname_plain: string
  email: string
  last_synced: string
  date_registered: string
  avg_stress_level_today: number | string | null
  avg_stress_level_yesterday: number | string
  avg_stress_level_2weeks: number | string | null
  avg_bbh_30days: number | string
  bbh_today: number | string
  bbh_yesterday: number | string
  rhr_today: number | string
  rhr_yesterday: number | string
  avg_o2_today: number | string
  avg_o2_yesterday: number | string
  systolic_today: number | string
  diastolic_today: number | string
  vigorous_intensity: number | string
  sleep_hours_today: number | string
  sleep_hours_30days: number | string
  sleep_quality_today: number | string
  sleep_quality_30days: number | string
  steps_today: number | string
  steps_yesterday: number | string
}

const muiCache = createCache({
  key: 'mui',
  prepend: true,
})

const getMuiTheme = () =>
  createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            textAlign: 'center',
          },
        },
      },
    },
  })

const Snapshot = () => {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthenticationContext);
  const [tableData, setTableData] = useState<IUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();


  const pages = [
    { name: 'Snapshot', url: '/snapshot'},
    { name: 'Mommy Details', url: '/mommy-details'},
    { name: 'Notifications', url: '/notifications' },
  ]

  const columns = [
    { name: 'Nickname', options: { filter: false, sort: true } },
    { name: 'Nickname Plain', options: { display: false, filter: false, sort: true } },
    { name: 'Last Synced', options: { filter: false, sort: true } },
    { name: 'Today (Yesterday\'s) [2 weeks] Average Stress Level', options: { filter: false, sort: true } },    
    { name: 'Today (Yesterday\'s) Body Battery High', options: { filter: false, sort: true } },
    { name: '30 Day Avg Body Battery High', options: { filter: false, sort: true } },
    { name: 'Today (Yesterday\'s) RHR', options: { filter: false, sort: true } },
    { name: 'Today Vigorous Intensity Minutes', options: { filter: false, sort: true } },
    { name: 'Today Sleep Hours (Quality)', options: { filter: false, sort: true } },
    { name: '30 Day Avg Sleep Hours (Quality)', options: { filter: false, sort: true } },
    { name: 'Today (Yesterday\'s) Avg O2 Sats', options: { filter: false, sort: false } },
    { name: 'Today Blood Pressure', options: { filter: false, sort: false } },
    { name: 'Today (Yesterday\'s) Steps', options: { filter: false, sort: false } }
  ]

  const options = {
    rowsPerPage: 100,
    rowsPerPageOptions: [10, 25, 50, 100, 500],
    viewColumns: false,
    filter: false,
    elevation: 0,
    selectableRows: 'none' as 'none',
    setTableProps: () => {
      return {
        size: 'small',
      }
    },
  }

  const handleNameClick = (user: any) => {

    let loadingPage = true
    navigate("/mommy-details", {
      state: {user, loadingPage},
      
    })
    
  }

  useEffect(() => {
    const data: any[] = []
    let allUsers: any[] = []

    setIsLoading(true);
    setError(null);

    axios.get(process.env.REACT_APP_API_URL + 'snapshot/', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      params: {
        'date': getTodaysDateFormatted()
      }
    }).then((response) => {

      allUsers = response.data.data
      allUsers.forEach((user) => {

        // const marked = <Checkbox defaultChecked={user.marked} onChange={(e) => handleCheckboxChange(e, user)} color="secondary" />
        const last_synced = new Date(user.last_synced) // Expects UTC 

        let systolic_today;
        let diastolic_today;

        if (user.systolic_today[0] !== undefined){
          systolic_today = user.systolic_today[0]
        } else {
          systolic_today = "-"
        }
        if (user.diastolic_today[0] !== undefined){
          diastolic_today = user.diastolic_today[0]
        } else {
          diastolic_today = "-"
        }

        

        data.push([

          <Tooltip title={user.email} placement="top" arrow>
            <Button onClick={() => handleNameClick(user)}>
              {user.nickname}
            </Button>
          </Tooltip>,
          user.nickname_plain,
          last_synced.toLocaleString(),
          String(user.avg_stress_level_today) + "(" + String(user.avg_stress_level_yesterday) + ") [" + String(user.avg_stress_level_2weeks) + "]",          
          String(user.bbh_today) + " (" + String(user.bbh_yesterday) + ")",
          user.avg_bbh_30days,
          String(user.rhr_today) + " (" + String(user.rhr_yesterday) + ")",
          user.vigorous_intensity,
          String(user.sleep_hours_today) + " (" + String(user.sleep_quality_today) + "%)",
          String(user.sleep_hours_30days) + " (" + String(user.sleep_quality_30days) + "%)",
          // String(user.sleep_quality_30days) + "%",
          String(user.avg_o2_today) + " (" + String(user.avg_o2_yesterday) + ")",
          String(systolic_today) + "/" + String(diastolic_today),
          String(user.steps_today) + " (" + String(user.steps_yesterday) + ")"
        ])        
      })
      setTableData(data)
      setIsLoading(false);
    })
  }, [])


  return (
    <>


      {isLoading &&
        <CircularProgress color="secondary" sx={{ display: 'block', margin: '0 auto' }} />}
      {<CacheProvider value={muiCache}>
        <ThemeProvider theme={getMuiTheme()}>
          {
            !isLoading && <DynamicSearchTable data={tableData} columns={columns} options={options} title='All Mommies'/> 
          }
        </ThemeProvider>
      </CacheProvider>}
      

    </>

  )
}

export default Snapshot