import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Autocomplete from '@mui/material/Autocomplete'
import SearchIcon from '@mui/icons-material/Search'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import AccordionDetails from '@mui/material/AccordionDetails'
import { useLocation } from 'react-router-dom';
import GenericPanelContent from './generic-panel-conent'
import { getTodaysDateFormatted } from '../../helpers/misc-functions'
import OverviewPanelContent from './overview-panel-content-v2'
import HealthMetricsModal from '../../components/monthly-summaries-modal'


const defaultSelectedUserInfo = {
  "garmin_user_access_token": '-',
  "first_name": '-',
  "last_name": '-'
}
interface IUserInfo {
  garmin_user_access_token: string
  first_name: string
  last_name: string
}

interface LocationState {
  user: any;
  loadingPage: boolean;
}

const User = () => {

  const location = useLocation();

  const [allUsers, setAllUsers] = useState<IUserInfo[]>([])
  const [selectedUser, setSelectedUser] = useState<IUserInfo | null>(null)
  const [searchText, setSearchText] = useState<string>('')
  const [showExportForm, setShowExportForm] = useState<boolean>(false)
  const [userHeartrateLoading, setUserHeartrateLoading] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(() => {
    return (location.state as LocationState)?.loadingPage ?? false;
  });
  const [error, setError] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [userHeartrateData, setUserHeartrateData] = useState<IHeartrateData[] | null>(null)
  const [userBloodPressureData, setUserBloodPressureData] = useState<IHeartrateData[] | null>(null)
  const [userBloodPressureLoading, setUserBloodPressureLoading] = useState<boolean>(false)
  const [userSleepData, setUserSleepData] = useState<ISleepData[] | null>(null)
  const [userSleepLoading, setUserSleepLoading] = useState<boolean>(false)
  const [userStressData, setUserStressData] = useState<IStressData[] | null>(null)
  const [userStressLoading, setUserStressLoading] = useState<boolean>(false)
  const [userWomenHealthData, setUserWomenHealthData] = useState<IWomenHealthData[] | null>(null)
  const [userWomenHealthLoading, setUserWomenHealthLoading] = useState<boolean>(false)
  const [userDetailData, setUserDetailData] = useState<IUserDetailData | null>(null)
  const [filterType, setFilterType] = useState<IChartFilter>('weekly')


  const handleSearch = async () => {
    
    let selectedUser = null

    const filteredData = allUsers.filter((element) => {
      
      // Grab selected input text and filter for them in data state variable
      if (searchText === '') {
        // if no input then return the original
        return false;
      } else {
        // Check for exact match, case insensitive
        return element.first_name.toLowerCase() === searchText.toLowerCase(); // return every data object in the array that includes the searchText in the fullName object field
      }
    })


    // Then, grab necessary pices of info in order to submit request to backend for getting more data on user
    // Then, store that data in a state variable before passing it on to the appropriate card below
    if (filteredData.length === 1) {
      // Make sure that only one match found TODO: what if two people with same name?
      selectedUser = filteredData[0]
      setSelectedUser(selectedUser)
    } else {
      selectedUser = defaultSelectedUserInfo
      // console.error("Too many users match the search criteria. Please review")
    }

    if (selectedUser !== null && selectedUser !== defaultSelectedUserInfo) {

      setIsLoading(true);
      setError(null);

      try {
        const response = await axios
          .get(process.env.REACT_APP_API_URL + 'user_details/', {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            },
            params: {
              'user': selectedUser.garmin_user_access_token,
              'average': { "by": "day", "value": 30 },
              'date': getTodaysDateFormatted()              
            }
          })
        if (response.status === 200) {          
          setUserDetailData(response.data.data)
          setSearchText('')
        }
      } catch (error: any) {
        setError('Search failed: ' + error.message)
      } finally {
        setIsLoading(false);
      }
    }
  }

  const clearSearch = () => {
    setSearchText('')
    setStartDate('')
    setEndDate('')
    setUserHeartrateData(null)
    setUserSleepData(null)
    setUserStressData(null)
    setUserWomenHealthData(null)
    setSelectedUser(null)
  }

  useEffect(() => {

    const state = location.state as any;
    
    if (state) {
      
      try {
        axios.get(process.env.REACT_APP_API_URL + 'user_details/', {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            },
            params: {
              'user': state.user.garmin_user_access_token,
              'average': { "by": "day", "value": 30 },
              'date': getTodaysDateFormatted()
              
            }
          }).then((response) => {
            setUserDetailData(response.data.data)
            setSelectedUser({
              garmin_user_access_token: state.user.garmin_user_access_token,
              first_name: state.user.nickname,
              last_name: '-'              
            })
          })
      } catch (error: any) {
        setError('Search failed: ' + error.message)
      } finally {
        setIsLoading(false);
      }
    }
  }, [location])

  // API Call to get user info data from backend
  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + 'users/', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      }
    }).then((response) => {
      setAllUsers(response.data.data)
    })
  }, [])

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={6} sm={8} md={10} xl={11} lg={11}>
        <Autocomplete
          forcePopupIcon={false}
          freeSolo // Set this to true in order to allow any arbitrary value
          id="selected-user-search"
          onChange={(event, value) => {
            setSearchText(value.toLowerCase())
          }}
          options={allUsers.map((option) => option.first_name)}
          disableClearable={true}
          onClose={(e, reason) => {
            if (reason === 'removeOption' || reason === 'blur') {
              clearSearch()
            }
          }}
          value={searchText}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                type: 'search',
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder="Search User"
              variant="outlined"
              size="small"
              fullWidth={true}
            />
          )}
        />
      </Grid>

      <Grid item={true} xs={6} sm={4} md={2} xl={1} lg={1}>
        <Button color="primary"
          variant="outlined"
          fullWidth={true}
          sx={{ height: '39px' }}
          onClick={handleSearch}
          disabled={isLoading}>
          {isLoading ?
            <CircularProgress color="secondary" size={24} sx={{ display: 'block', margin: 'auto' }} /> :
            'Search'}
        </Button>
      </Grid>

      <Grid item={true} xs={12}>
      <div style={{ textAlign: 'center', color: '#1976d2'}}>
        <h1>
        {isLoading ?
            <CircularProgress color="secondary" size={24} sx={{ display: 'block', margin: 'auto' }} /> :            
              selectedUser ? 
                `${selectedUser.first_name}` : 'No user selected'            
            }          
        </h1>
      </div>

      <HealthMetricsModal data={userDetailData}/>

      <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon data-testid="expand-panel-1" />}>
            <Typography sx={{ width: '33%', flexShrink: 0 }}>Overview</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <OverviewPanelContent selectedUser={selectedUser} userDetailData={userDetailData} filterType={filterType}/>
          </AccordionDetails>
        </Accordion> 

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon data-testid="expand-panel-1" />}>
            <Typography sx={{ width: '33%', flexShrink: 0 }}>Heartrate</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <GenericPanelContent filterType={filterType} selectedUser={selectedUser} userDetailData={userDetailData} panelType='heartrate' showDailyFilter={true}  />
          </AccordionDetails>
        </Accordion> 

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon data-testid="expand-panel-1" />}>
            <Typography sx={{ width: '33%', flexShrink: 0 }}>O2 Sats</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <GenericPanelContent filterType={filterType} selectedUser={selectedUser} userDetailData={userDetailData} panelType='o2-sats' showDailyFilter={true} />
          </AccordionDetails>
        </Accordion> 


        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon data-testid="expand-panel-1" />}>
            <Typography sx={{ width: '33%', flexShrink: 0 }}>Stress</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <GenericPanelContent filterType={filterType}  selectedUser={selectedUser} userDetailData={userDetailData} panelType='stress' showDailyFilter={true} />
          </AccordionDetails>
        </Accordion> 

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon data-testid="expand-panel-1" />}>
            <Typography sx={{ width: '33%', flexShrink: 0 }}>Body Battery</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <GenericPanelContent filterType={filterType} selectedUser={selectedUser} userDetailData={userDetailData} panelType='body-battery' showDailyFilter={true} />
          </AccordionDetails>
        </Accordion> 

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon data-testid="expand-panel-1" />}>
            <Typography sx={{ width: '33%', flexShrink: 0 }}>Intensity Minutes</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <GenericPanelContent filterType={filterType} selectedUser={selectedUser} userDetailData={userDetailData} panelType='intensity-minutes' showDailyFilter={false} />
          </AccordionDetails>
        </Accordion> 

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon data-testid="expand-panel-1" />}>
            <Typography sx={{ width: '33%', flexShrink: 0 }}>Sleep</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <GenericPanelContent filterType={filterType} selectedUser={selectedUser} userDetailData={userDetailData} panelType='sleep' showDailyFilter={false} />
          </AccordionDetails>
        </Accordion> 

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon data-testid="expand-panel-1" />}>
            <Typography sx={{ width: '33%', flexShrink: 0 }}>Women's Health</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <GenericPanelContent filterType={filterType} selectedUser={selectedUser} userDetailData={userDetailData} panelType='womens-health' showDailyFilter={false} />
          </AccordionDetails>
        </Accordion> 

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon data-testid="expand-panel-1" />}>
            <Typography sx={{ width: '33%', flexShrink: 0 }}>Steps</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <GenericPanelContent filterType={filterType} selectedUser={selectedUser} userDetailData={userDetailData} panelType='steps' showDailyFilter={false} />
          </AccordionDetails>
        </Accordion> 

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon data-testid="expand-panel-1" />}>
            <Typography sx={{ width: '33%', flexShrink: 0 }}>Blood Pressure</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <GenericPanelContent filterType={'daily'} selectedUser={selectedUser} userDetailData={userDetailData} panelType='blood-pressure' showDailyFilter={true} />
          </AccordionDetails>
        </Accordion> 
      </Grid>

    </Grid>
  )
}

export default User