import { Field, FieldProps } from 'formik'
import { FormControl, MenuItem, Select, SelectProps } from '@mui/material'

type IProps = {
  name: string // formik field name
  dropdownItems: string[] // list of options in select element
} & SelectProps

const FormikSelect = (props: IProps) => {
  const { name, dropdownItems, ...rest } = props

  return (
    <Field name={name}>
      {({ field }: FieldProps<any>) => {
        return (
          <FormControl fullWidth={true} size="small">
            <Select {...rest} {...field}>
              {dropdownItems.map((item, idx) => (
                <MenuItem key={idx} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      }}
    </Field>
  )
}

export default FormikSelect
