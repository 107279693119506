import React, { useState, useMemo, ReactElement } from 'react';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableColumn } from 'mui-datatables';
import { TextField, InputAdornment, Toolbar, debounce } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface IUser {
  nickname: ReactElement | string
  nickname_plain: string
  email: string
  last_synced: string
  date_registered: string
  avg_stress_level_today: number | string | null
  avg_stress_level_yesterday: number | string
  avg_stress_level_2weeks: number | string | null
  avg_bbh_30days: number | string
  bbh_today: number | string
  bbh_yesterday: number | string
  rhr_today: number | string
  rhr_yesterday: number | string
  avg_o2_today: number | string
  avg_o2_yesterday: number | string
  systolic_today: number | string
  diastolic_today: number | string
  vigorous_intensity: number | string
  sleep_hours_today: number | string
  sleep_hours_30days: number | string
  sleep_quality_today: number | string
  sleep_quality_30days: number | string
  steps_today: number | string
  steps_yesterday: number | string
}


interface DynamicSearchTableProps {
  data: any[];
  columns: MUIDataTableColumn[];
  title: string;
  options?: Partial<MUIDataTableOptions>;
}

const DynamicSearchTable: React.FC<DynamicSearchTableProps> = ({
  data,
  columns,
  title,
  options: userOptions = {}
}) => {

  const [searchTerm, setSearchTerm] = useState('');

  const filteredData = useMemo(() => {

    if (!searchTerm) {
      return data
    } else {
      return data.filter(row =>{
        return row[1].toLowerCase().includes(searchTerm.toLowerCase())      
    });
    }
    
  }, [data, searchTerm]);


  const defaultOptions: MUIDataTableOptions = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: 'none',
    search: false,
    customToolbar: () => {
      return (
        <Toolbar style={{ width: '98%', maxWidth: 'none' }}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search by nickname..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            style={{ width: '98%' }}
          />
        </Toolbar>
      );
    },
  };

  const mergedOptions: MUIDataTableOptions = {
    ...defaultOptions,
    ...userOptions,
  };

  return (
    <MUIDataTable
      title={title}
      data={filteredData}
      columns={columns}
      options={mergedOptions}
    />
  );
};

export default DynamicSearchTable;