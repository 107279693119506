import { Grid, Typography } from '@mui/material'
import FormikInput from '../../../components/formik-input'

const LastSynced = () => {
  return (
    <Grid item={true} xs={12} container={true} alignItems="center" spacing={3}>
      <Grid item={true} xs={2}>
        <Typography variant="subtitle1">Last Synced</Typography>
      </Grid>

      <Grid item={true} xs={2}>
        <Typography variant="subtitle1">is greater than or equal to</Typography>
      </Grid>

      <Grid item={true} xs={2}>
        <FormikInput name="lastSynced.greaterOrEqualDays" inputLabel="Days" size="small" type="number" />
      </Grid>
    </Grid>
  )
}

export default LastSynced
