import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { HEADER_HEIGHT, DRAWER_WIDTH } from '../../helpers/constants'
import { Link, useLocation } from 'react-router-dom'
import { useContext } from 'react'
import { SidenavContext } from '../../providers/sidenav'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'

const menuItems = [
  { name: 'Snapshot', url: '/snapshot', icon: <PeopleAltIcon /> },
  { name: 'Mommy Details', url: '/mommy-details', icon: <PersonIcon /> },
  { name: 'Notifications', url: '/notifications', icon: <NotificationsIcon /> },
]

const Sidenav = () => {
  const location = useLocation()
  const { isSidenavOpen } = useContext<ISidenavContext>(SidenavContext)

  return (
    <Drawer
      sx={{
        marginTop: HEADER_HEIGHT,
        width: DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: HEADER_HEIGHT,
        },
      }}
      PaperProps={{
        sx: {
          marginTop: HEADER_HEIGHT,
        },
      }}
      variant="persistent"
      anchor="left"
      open={isSidenavOpen}
    >
      <List>
        {menuItems.map((sidenavItem, index) => (
          <ListItem key={index} disablePadding={true} component={Link} to={sidenavItem.url}>
            <ListItemButton sx={{ color: 'black' }}>
              <ListItemIcon>{sidenavItem.icon}</ListItemIcon>
              <ListItemText primary={sidenavItem.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  )
}

export default Sidenav
