import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Box,
  IconButton,
  Typography,
  Tooltip
} from '@mui/material';
import {
  Download as DownloadIcon,
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
  Remove as RemoveIcon
} from '@mui/icons-material';
import html2canvas from 'html2canvas';

// Define interfaces for our data structures
// Create a union type for metrics to avoid string literals
type MetricKey = 
  | 'restingHeartRate'
  | 'oxygenSaturation'
  | 'sleepHours'
  | 'sleepQuality'
  | 'bodyBatteryHigh'
  | 'bodyBatteryLow'
  | 'vigorousIntensityMinutes'
  | 'steps'
  | 'endOfDayStress';

interface HealthMetric {
  key: MetricKey;
  label: string;
  unit: string;
}

// Create a mapping type for data access
type MetricDataMapping = {
  restingHeartRate: 'heart_rate';
  oxygenSaturation: 'o2';
  sleepHours: 'sleep';
  sleepQuality: 'monthly_avg_sleepquality';
  bodyBatteryHigh: 'monthly_avg_body_battery_high';
  bodyBatteryLow: 'monthly_avg_body_battery_low';
  vigorousIntensityMinutes: 'intensity_vigorous';
  steps: 'steps';
  endOfDayStress: 'stress';
};

interface IProps {
  data: IUserDetailData | null
}


const HealthMetricsModal: React.FC<IProps> = ({data}) => {

  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const metrics = useMemo<HealthMetric[]>(() => [
    { key: 'restingHeartRate', label: 'Resting Heart Rate', unit: 'bpm'},
    { key: 'oxygenSaturation', label: 'Oxygen Saturation', unit: '%'},
    { key: 'sleepHours', label: 'Sleep Hours', unit: 'hrs'},
    { key: 'sleepQuality', label: 'Sleep Quality', unit: '/100'},
    { key: 'bodyBatteryHigh', label: 'Body Battery High', unit: ''},
    { key: 'bodyBatteryLow', label: 'Body Battery Low', unit: ''},
    { key: 'vigorousIntensityMinutes', label: 'Vigorous Intensity Minutes', unit: 'mins'},
    { key: 'steps', label: 'Steps', unit: ''},
    { key: 'endOfDayStress', label: 'End of Day Stress', unit: ''},
  ], [])


  const metricToDataKeyMap = useMemo<MetricDataMapping>(() => ({
    restingHeartRate: 'heart_rate',
    oxygenSaturation: 'o2',
    sleepHours: 'sleep',
    sleepQuality: 'monthly_avg_sleepquality',
    bodyBatteryHigh: 'monthly_avg_body_battery_high',
    bodyBatteryLow: 'monthly_avg_body_battery_low',
    vigorousIntensityMinutes: 'intensity_vigorous',
    steps: 'steps',
    endOfDayStress: 'stress'
  }), [])

  const months = useMemo<string[]>(() => {
    if (!data) return []    

    return Object.keys(data.heart_rate.monthly);
  }, [data]) // only recalculate when data changes

  const getData = useCallback((month: string, metric: MetricKey) => {
    if (!data) return "-";
    const dataKey = metricToDataKeyMap[metric];
    return data[dataKey]?.monthly[month] ?? "-";
  }, [data, metricToDataKeyMap])

  const handleDownload = useCallback(async (): Promise<void> => {
    try {
      setIsLoading(true);
      const tableElement = document.getElementById('health-metrics-table');
      if (!tableElement) throw new Error('Table element not found');

      // Use blob for better memory management
      const canvas = await html2canvas(tableElement, {
          backgroundColor: '#ffffff',
          scale: 2, // for better quality
          logging: false
      });

      // Convert to blob instead of data URL for better memory handling
      const blob = await new Promise<Blob>(resolve => {
        canvas.toBlob(blob => resolve(blob!), 'image/png');
      });

      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'health-metrics-7-month-history.png';
      link.click();
    } catch (err) {
      setError('Failed to download image');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const resetStates = () => {
      setIsLoading(false);
      setError(null);
    };  
    // Reset states when modal closes
    if (!open) {
      resetStates();
    }  
    // Use same reset function for unmount
    return resetStates;
  }, [open]);


  return (
    <>
      <Button 
        variant="outlined" 
        onClick={() => setOpen(true)}
        startIcon={<TrendingUpIcon />}
      >
        Monthly Summaries
      </Button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">7-Month Health Metrics History</Typography>
            <IconButton onClick={handleDownload} size="small" color="primary">
              <DownloadIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Box id="health-metrics-table">
            <TableContainer component={Paper} elevation={2}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Metric</strong></TableCell>
                    {
                      months.map(month => (
                        <TableCell key={month} align="center">
                          <strong>{month}</strong>
                        </TableCell>
                      ))
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {metrics.map(metric => {
                    return (
                      <TableRow key={metric.key} hover>
                        <TableCell>
                          <Typography variant="body2">
                            {metric.label}
                            <Typography
                              component="span"
                              variant="caption"
                              color="text.secondary"
                              sx={{ ml: 0.5 }}
                            >
                              ({metric.unit})
                            </Typography>
                          </Typography>
                        </TableCell>
                        {months.map(month => (
                          <TableCell
                            key={`${month}-${metric.key}`}
                            align="center"
                          >
                            {getData(month, metric.key)}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default HealthMetricsModal;