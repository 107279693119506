import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { Form, Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import FormikInput from '../../components/formik-input'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'

interface IForgotPasswordFormValues {
  email: string
}

const ForgotPassword = () => {
  const history = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const initialValues = {
    email: '',
  }

  const schema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Must be a valid email'),
  })

  const handleForgotPassword = (values: IForgotPasswordFormValues) => {
    // TODO: send API request here, you can get the email to pass to an API from values

    setTimeout(() => {
      history('/')
      enqueueSnackbar('Reset instructions were sent', { variant: 'success' })
    }, 200)
  }

  return (
    <Grid container={true} spacing={10} justifyContent="center">
      <Grid item={true} xs={11} sm={10} md={7} lg={6} xl={6} sx={{ marginTop: '7%' }}>
        <Card>
          <Box sx={{ backgroundColor: 'primary.main', height: '40px', padding: '10px', color: 'white', fontSize: '24px' }}>Mommy Monitor | Forgot Password</Box>

          <Formik initialValues={initialValues} onSubmit={handleForgotPassword} validationSchema={schema}>
            {(formikBag: FormikProps<IForgotPasswordFormValues>) => {
              const { isValid, dirty } = formikBag

              return (
                <Form>
                  <Grid container={true} spacing={2} justifyContent="center" sx={{ marginTop: '15px' }}>
                    <Grid item={true} xs={10}>
                      <FormikInput name="email" inputLabel="Email" variant="outlined" size="small" />
                    </Grid>

                    <Grid item={true} xs={6} container={true} justifyContent="center">
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!isValid || !dirty}
                        size="small"
                        sx={{ margin: '0 0 15px 10px' }}
                        data-testid="forgot-btn"
                      >
                        Forgot Password
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>

          <Grid item={true} xs={12} container={true} justifyContent="center">
            <Button onClick={() => history('/')} color="primary" sx={{ marginBottom: '10px', textTransform: 'capitalize' }}>
              Go Back to Login
            </Button>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

export default ForgotPassword
