import { IconButton, Toolbar, AppBar, Typography, Button } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { useContext } from 'react'
import { SidenavContext } from '../../providers/sidenav'
import { useNavigate } from 'react-router-dom'
import { AuthenticationContext } from '../../providers/authentication'
import { useSnackbar } from 'notistack'
import { removeAccessTokenCookie } from '../../helpers/misc-functions'

const Header = () => {
  const { toggleSidenav } = useContext<ISidenavContext>(SidenavContext)
  const { setIsLoggedIn } = useContext(AuthenticationContext)
  const history = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const handleLogout = () => {
    // TODO: send an API request to logout here
    history('/')
    removeAccessTokenCookie()
    setIsLoggedIn(false)
    enqueueSnackbar('You have been successfully logged out', { variant: 'success' })
  }

  return (
    <AppBar position="fixed">
      <Toolbar>
        <IconButton color="inherit" aria-label="sidenav drawer" onClick={() => toggleSidenav()} edge="start" data-testid="toggle-sidenav-btn">
          <MenuIcon />
        </IconButton>

        <Typography variant="h6" noWrap component="div">
          Mommy Monitor
        </Typography>

        <Button onClick={handleLogout} sx={{ color: 'white', marginLeft: 'auto' }}>
          Logout
        </Button>

      </Toolbar>
    </AppBar>
  )
}

export default Header
