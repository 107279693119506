import { useState } from 'react'
import axios, { AxiosError } from 'axios'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { Form, Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import FormikInput from '../../components/formik-input'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { AuthenticationContext } from '../../providers/authentication'
import { setAccessTokenCookie, setRefreshTokenCookie, refreshAccessToken} from '../../helpers/misc-functions'


interface ILoginFormValues {
  username: string
  password: string
}



const Login = () => {
  const navigate = useNavigate()
  
  // const { setIsLoggedIn } = useContext(AuthenticationContext)

  const { isLoggedIn, setIsLoggedIn } = useContext(AuthenticationContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const initialValues = {
    username: '',
    password: '',
  }

  const schema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  })


  const handleLogin = async (values: ILoginFormValues) => {
    // TODO: send API request here, you can get the email and password entered from values
    // If API returned 200, do setIsLoggedIn(true)
    // If API errored out, do setIsLoggedIn(false)
    // also update AuthProvider when you log user out (i.e. when Logout button is clicked)
    // you may want to cintercept all requests, and when 401 is detected, log user out automatically for better UX experience (when your login session expires)


    // Get authentication token
    if (process.env.REACT_APP_MM_AUTH_URL) {

      setIsLoading(true);
      setError(null);

      try {

        const response = await axios
          .post(process.env.REACT_APP_MM_AUTH_URL, {
            username: values.username,
            password: values.password
          })


        // Handle successful login
        if (response.status === 200) {
          setAccessTokenCookie(response.data.access)
          setRefreshTokenCookie(response.data.refresh)
          // axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;

          // axios.interceptors.request.use(
          //   (config) => {
          //     if (response.data.access) {
          //       console.log("config")
          //       console.log(config)
          //       console.log(config.headers)
          //       // Ensure headers exist before setting the Authorization header
          //       if (!config.headers) {
          //         config.headers = {};
          //         console.log("Hello")
          //       } else {
          //         config.headers['Authorization'] = `Bearer ${response.data.access}`;
          //         console.log("World")
          //       }

          //       return config;
          //     } else {
          //       setIsLoggedIn(false)
          //     }
          //   }
          // );

          // axios.defaults.headers['Authorization'] = `Bearer ${response.data.access}`;

          setTimeout(() => {
            setIsLoggedIn(true)
            navigate('/snapshot')
            
          }, 300)

        } else {
          setIsLoggedIn(false)
        }

      } catch (error: any) {

        if (error.response && error.response.status === 401) {
          try {
            // Attempt to refresh the access token
            await refreshAccessToken();

          } catch (refreshError: any) {
            // If token refresh also fails, handle it (e.g., redirect to login, show error)
            setError('Login failed. Please try again.');
          }
        } else {
          // If the error is not due to token expiration, handle it as a generic login failure
          setError('Login failed: ' + error.message);
        }

      } finally {
        setIsLoading(false);
      }

    } else {
      console.error("Auth URL is not defined")
    }
  }

  return (
    <Grid container={true} spacing={10} justifyContent="center">
      <Grid item={true} xs={11} sm={10} md={7} lg={6} xl={6} sx={{ marginTop: '7%' }}>
        <Card>
          <Box sx={{ backgroundColor: 'primary.main', height: '40px', padding: '10px', color: 'white', fontSize: '24px' }}> Mommy Monitor</Box>

          <Formik initialValues={initialValues} onSubmit={handleLogin} validationSchema={schema}>
            {(formikBag: FormikProps<ILoginFormValues>) => {
              const { isValid, dirty } = formikBag

              return (
                <Form>
                  <Grid container={true} spacing={2} justifyContent="center" sx={{ marginTop: '15px' }}>
                    <Grid item={true} xs={10}>
                      <FormikInput name="username" inputLabel="Username" variant="outlined" size="small" />
                    </Grid>

                    <Grid item={true} xs={10} data-cy="password">
                      <FormikInput type="password" name="password" inputLabel="Password" variant="outlined" size="small" />
                    </Grid>

                    <Grid item={true} xs={3} container={true} justifyContent="center">
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!isValid || !dirty || isLoading}
                        size="small"
                        sx={{ margin: '0 0 15px 10px' }}
                        data-testid="login-btn"
                      >
                        {isLoading ? 'Logging in...' : 'Login'}
                      </Button>

                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>

          {/* <Grid item={true} xs={12} container={true} justifyContent="center">
            <Button onClick={() => navigate('/forgot-password')} color="primary" sx={{ marginBottom: '10px', textTransform: 'capitalize' }}>
              Forgot Password?
            </Button>
          </Grid> */}
        </Card>
      </Grid>
    </Grid>
  )
}

export default Login