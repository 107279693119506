import Button from '@mui/material/Button'
import { grey } from '@mui/material/colors'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Formik, Form, FormikHelpers } from 'formik'
import axios from 'axios'
import NotificationDetailsFormArray from './formik-field-array'
import { useSnackbar } from 'notistack'
import BodyBattery from './body-battery'
import O2Saturation from './o2-saturation'
import BloodPressure from './blood-pressure'
import Stress from './stress'
import SleepQuality from './sleep-quality'
import RHR from './rhr'
import LastSynced from './last-synced'
import SleepData from './sleep-data'
import IntensityMinutes from './intensity-minutes'
import * as Yup from 'yup'

const Notifications = () => {
  const { enqueueSnackbar } = useSnackbar()

  const schema = Yup.object().shape({
    bodyBattery: Yup.object().shape({
      points: Yup.number().min(0, 'Must be a positive number'),
      daysInRow: Yup.number().min(0, 'Must be a positive number'),
    }),
    o2saturation: Yup.object().shape({
      dropsBelowPercentage: Yup.number().min(0, 'Must be a positive number'),
      fallsBetweenValue1: Yup.number().min(0, 'Must be a positive number'),
      fallsBetweenValue2: Yup.number().min(0, 'Must be a positive number'),
      decreaseByPercentage: Yup.number().min(0, 'Must be a positive number'),
    }),
    bloodPressure: Yup.object().shape({
      systolicIncreaseAmount: Yup.number().min(0, 'Must be a positive number'),
      diastolicIncreaseAmount: Yup.number().min(0, 'Must be a positive number'),
    }),
    stress: Yup.object().shape({
      pointsAbove: Yup.number().min(0, 'Must be a positive number'),
    }),
    sleepQuality: Yup.object().shape({
      sleepQualityEqualsTo: Yup.number().min(0, 'Must be a positive number'),
    }),
    rhr: Yup.object().shape({
      increaseByPercentage: Yup.number().min(0, 'Must be a positive number'),
    }),
    lastSynced: Yup.object().shape({
      greaterOrEqualDays: Yup.number().min(0, 'Must be a positive number'),
    }),
    intensityMinutes: Yup.object().shape({
      greaterThanOrEqualMinutes: Yup.number().min(0, 'Must be a positive number'),
    }),
  })

  // TODO: if you receive previously created notifications from API and want to show them, you can do it here
  const initialValues = {
    bodyBattery: {
      points: '',
      duration: 'monthly average',
      daysInRow: '',
    },
    o2saturation: {
      dropsBelowPercentage: '',
      fallsBetweenValue1: '',
      fallsBetweenValue2: '',
      fallsBetweenDuration: 'night',
      decreaseByPercentage: '',
      decreaseByPercentageDuration: 'monthly average',
    },
    bloodPressure: {
      systolicIncreaseAmount: '',
      systolicIncreaseDuration: 'monthly average',
      diastolicIncreaseAmount: '',
      diastolicIncreaseDuration: 'monthly average',
    },
    stress: {
      pointsAbove: '',
      stressDuration: 'weekly average',
    },
    sleepQuality: {
      sleepQualityEqualsTo: '',
    },
    rhr: {
      increaseByPercentage: '',
      increaseByDuration: 'monthly average',
    },
    lastSynced: {
      greaterOrEqualDays: '',
    },
    sleepData: {
      sleepDataPresense: 'missing',
    },
    intensityMinutes: {
      greaterThanOrEqualMinutes: '',
      timePeriod: '24 hours',
    },
  }

  const heartrateDropdownOptions = [
    'Active Kilocalories',
    'BMR Kilocalories',
    'Consumed Calories',
    'Steps',
    'Distance In Meters',
    'Duration In Seconds',
    'Active Time In Seconds',
    'Start Time In Seconds',
    'Start Time Offset In Seconds',
    'Moderate Intensity Duration In Seconds',
    'Vigorous Intensity Duration In Seconds',
    'Floors Climbed',
    'Min Heart Rate In Beats Per Minute',
    'Average Heart Rate In Beats Per Minute',
    'Max Heart Rate In Beats Per Minute',
    'Average Stress Level',
    'Max Stress Level',
    'Stress Duration In Seconds',
    'Rest Stress Duration In Seconds',
    'Activity Stress Duration In Seconds',
    'Low Stress Duration In Seconds',
    'Medium Stress Duration In Seconds',
    'High Stress Duration In Seconds',
    'Steps Goal',
    'Net Kilocalories Goal',
    'Intensity Duration Goal In Seconds',
    'Floors Climbed Goal',
  ]

  const sleepDropdownOptions = [
    'Duration In Seconds',
    'Start Time In Seconds',
    'Start Time Offset In Seconds',
    'Unmeasurable Sleep Duration In Seconds',
    'Deep Sleep Duration In Seconds',
    'Light Sleep Duration In Seconds',
    'REM Sleep In Seconds',
    'Awake Duration In Seconds',
  ]

  const stressDropdownOptions = ['Start Time In Seconds', 'Start Time Offset In Seconds', 'Duration In Seconds', 'Average Stress Level']

  const womenHealthDropdownOptions = [
    'Day In Cycle',
    'Period Length',
    'Current Phase',
    'Length Of Current Phase',
    'Days Until Next Phase',
    'Predicted Cycle Length',
    'Cycle Length',
    'Last Updated Time In Seconds',
  ]

  const submitNotification = async (values: any, formikHelpers: FormikHelpers<any>) => {
    // TODO: you can send a request to the API here, the values you need to send will be in "values" printed below:


    const notificationsToSave = values

    console.log('values from submit notification btn: ', notificationsToSave)

    axios
      .post(process.env.REACT_APP_API_URL + 'notifications/create', {
        notificationsToSave
      })
      .then((response) => {
        console.log('Response from Notificaitons Create Endpoint: ', response)
        // If api response is 200 ok then display timed alert below
        setTimeout(() => {
          enqueueSnackbar(' Notification was successfully submitted', { variant: 'success' })
        }, 600)
      })
      .catch(function (error) {
        if (axios.isAxiosError(error)) {
          console.log('Axios Error Message: ', error.message)
          return error.message
        } else {
          console.log('Unexpected Error When Creating Notifications: ', error)
          return 'An unexpected error occurred'
        }
      })



  }

  // const updateNotification = async (values: any) => {
  //   // TODO: you can send a request to the API here, the values you need to send will be in "values" printed below:
  //   console.log('values from update notification btn: ', values)

  //   // If api response is 200 ok then display timed alert below
  //   setTimeout(() => {
  //     enqueueSnackbar(' Notification was successfully updated', { variant: 'success' })
  //   }, 600)
  // }

  return (
    <>
      <Typography variant="h6" gutterBottom={true}>
        For all users, notify me when
      </Typography>

      <Formik initialValues={initialValues} onSubmit={submitNotification} validationSchema={schema}>
        {({ values, isValid }: any) => {
          // console.log('form values: ', values)
          return (
            <Form>
              <Grid container={true} spacing={2} sx={{ marginTop: '10px' }}>
                {/* <Grid item={true} xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: 600, color: grey[700] }}>
                    Heartrate
                  </Typography>
                </Grid>

                <Grid item={true} xs={12}>
                  <NotificationDetailsFormArray items={values['heartrate-details']} name="heartrate-details" dropdownOptions={heartrateDropdownOptions} />
                </Grid>

                <Grid item={true} xs={12}>
                  <Divider />
                </Grid> */}

                {/* <Grid item={true} xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: 600, color: grey[700] }}>
                    Sleep
                  </Typography>
                </Grid>

                <Grid item={true} xs={12}>
                  <NotificationDetailsFormArray items={values['sleep-details']} name="sleep-details" dropdownOptions={sleepDropdownOptions} />
                </Grid>

                <Grid item={true} xs={12}>
                  <Divider />
                </Grid> */}

                {/* <Grid item={true} xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: 600, color: grey[700] }}>
                    Stress
                  </Typography>
                </Grid>

                <Grid item={true} xs={12}>
                  <NotificationDetailsFormArray items={values['stress-details']} name="stress-details" dropdownOptions={stressDropdownOptions} />
                </Grid>

                <Grid item={true} xs={12}>
                  <Divider />
                </Grid> */}

                {/* <Grid item={true} xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: 600, color: grey[700] }}>
                    Women's Health
                  </Typography>
                </Grid>

                <Grid item={true} xs={12}>
                  <NotificationDetailsFormArray
                    items={values['women-health-details']}
                    name="women-health-details"
                    dropdownOptions={womenHealthDropdownOptions}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <Divider />
                </Grid> */}

                <Grid item={true} xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: 600, color: grey[700] }}>
                    Custom Notifications
                  </Typography>
                </Grid>

                <BodyBattery />

                <O2Saturation />

                <BloodPressure />

                <Stress />

                <SleepQuality />

                <RHR />

                <LastSynced />

                <SleepData />

                <IntensityMinutes />

                <Grid item={true} xs={12}>
                  <Divider />
                </Grid>

                <Grid item={true} xs={12}>
                  <Button type="submit" variant="contained" color="success" disabled={!isValid}>
                    Save
                  </Button>
                  {/* <Button variant="contained" color="secondary" disabled={!isValid} onClick={() => updateNotification(values)} sx={{ marginLeft: '10px' }}>
                    Update
                  </Button> */}
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default Notifications
