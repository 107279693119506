export const HEADER_HEIGHT = '64px'
export const DRAWER_WIDTH = 230

export const IS_AUTHENTICATED = 'is_authenticated'
export const IS_AUTHENTICATED_VALUE = 'mommy_monitor_authenticated'

export const metricColors = {
    heartRate: {
      resting: '#85144b',
      average: '#FF4136',
      max: '#B10DC9'
    },
    stress: {
      average: '#FF851B'
    },
    bodyBattery: {
      high: '#2ECC40',
      low: '#01FF70'
    },
    sleep: {
      deep: '#B10DC9',
      light: '#F012BE',
      rem: '#85144b',
      awake: '#AAAAAA',
      quality: '#111111',
      hours: '#85144b'
    },
    o2: {
      average: '#0074D9',
      low: '#7FDBFF'
    },
    activity: {
      steps: '#FFDC00',
      moderateIntensity: '#FFA07A',
      vigorousIntensity: '#FF6347'
    }
   } as const;
   
   // If you need direct access to individual colors without the grouping:
   export const chartColors = {
    restingHR: metricColors.heartRate.resting,
    avgHR: metricColors.heartRate.average,
    maxHR: metricColors.heartRate.max,
    stress: metricColors.stress.average,
    bodyBatteryHigh: metricColors.bodyBattery.high,
    bodyBatteryLow: metricColors.bodyBattery.low,
    deepSleep: metricColors.sleep.deep,
    lightSleep: metricColors.sleep.light,
    remSleep: metricColors.sleep.rem,
    awakeSleep: metricColors.sleep.awake,
    qualitySleep: metricColors.sleep.quality,
    sleepHours: metricColors.sleep.hours,
    avgO2: metricColors.o2.average,
    lowO2: metricColors.o2.low,
    steps: metricColors.activity.steps,
    moderateIntensity: metricColors.activity.moderateIntensity,
    vigorousIntensity: metricColors.activity.vigorousIntensity
   } as const;