import FormHelperText from '@mui/material/FormHelperText'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { Field, FieldProps, getIn } from 'formik'

type IProps = {
  name: string
  inputLabel: string
} & TextFieldProps

const FormikInput = (props: IProps) => {
  const { name, inputLabel, ...rest } = props

  const renderTextField = ({ field, form }: FieldProps<any>) => {
    const error = getIn(form.errors, name)
    const touched = getIn(form.touched, name)

    return (
      <>
        <TextField fullWidth={true} {...rest} {...field} error={Boolean(touched && error)} label={inputLabel} margin="normal">
          {props.children}
        </TextField>

        {<FormHelperText sx={{ color: '#e53935', marginTop: 0 }}>{error && touched ? error : ''}</FormHelperText>}
      </>
    )
  }

  return <Field name={name}>{renderTextField}</Field>
}

export default FormikInput
