import { Grid, Typography } from '@mui/material'
import FormikInput from '../../../components/formik-input'
import FormikSelect from '../../../components/formik-select'

const RHR = () => {
  return (
    <Grid item={true} xs={12} container={true} alignItems="center" spacing={3}>
      <Grid item={true} xs={2}>
        <Typography variant="subtitle1">RHR</Typography>
      </Grid>

      <Grid item={true} xs={2}>
        <Typography variant="subtitle1">increases by</Typography>
      </Grid>

      <Grid item={true} xs={2}>
        <FormikInput name="rhr.increaseByPercentage" inputLabel="%" size="small" type="number" />
      </Grid>

      <Grid item={true} xs={2}>
        <Typography variant="subtitle1">from the</Typography>
      </Grid>

      <Grid item={true} xs={3}>
        <FormikSelect name="rhr.increaseByDuration" dropdownItems={['monthly average', 'weekly average']} sx={{ marginTop: '1px' }} />
      </Grid>
    </Grid>
  )
}

export default RHR
