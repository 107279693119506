import { Grid, Typography } from '@mui/material'
import { endOfWeek, format, startOfWeek, subDays } from 'date-fns'

////
// This file is for setting and modifying the dates in the tiles of the charts
////


interface IProps {
  selectedFilter: IChartFilter | null
  customRangeStartDate: Date | null //custom filter dates set via calendar
  customRangeEndDate: Date | null // custom fitler dates set via calendar
  dateRangeStartDate: string | null // date range dates when weekly or monthly fitler is selected
  dateRangeEndDate: string | null // date range dates when weekly or monthly fitler is selected
  singleDay: string | null // for single day
}

const SelectedTimeline = (props: IProps) => {
  const { selectedFilter, customRangeStartDate, customRangeEndDate, dateRangeStartDate, dateRangeEndDate, singleDay } = props

  const getDates = () => {
    if (customRangeStartDate && customRangeEndDate) {
      return `${format(customRangeStartDate, 'yyyy-MM-dd')} to ${format(customRangeEndDate, 'yyyy-MM-dd')}`
    } else if (dateRangeStartDate && dateRangeEndDate) {
      return `${dateRangeStartDate} to ${dateRangeEndDate}`
    }
    // show dates for weekly filter when arrow buttons are not clicked
    else if (selectedFilter === 'weekly' && !dateRangeStartDate && !dateRangeEndDate) {
      const currentWeekBeginDate = format(startOfWeek(new Date()), 'yyyy-MM-dd')
      const currentWeekEndDate = format(endOfWeek(new Date()), 'yyyy-MM-dd')

      return `${currentWeekBeginDate} to ${currentWeekEndDate}`
    }
    // show dates for monthly filter when arrow buttons are not clicked
    else if (selectedFilter === 'monthly' && !dateRangeStartDate && !dateRangeEndDate) {
      const from = new Date()
      const prevMonth = subDays(from, 28)
      const prevMonthBeginDate = format(startOfWeek(new Date(prevMonth)), 'yyyy-MM-dd')
      const prevMonthEndDate = format(endOfWeek(new Date()), 'yyyy-MM-dd')

      return `${prevMonthBeginDate} to ${prevMonthEndDate}`
    }
    // for single day
    else if (singleDay) {
      return singleDay
    }
    // if arrows weren't used yet but current fitler is today, show today date
    else if (selectedFilter === 'daily' && !singleDay) {
      return format(new Date(), 'yyy-MM-dd')
    }
  }

  return getDates() ? (
    <Grid container={true} spacing={0}>
      <Grid item={true} xs={12}>
        <Typography variant="body1" gutterBottom={true} color="primary">
          {selectedFilter === 'daily' ? 'Selected Date' : 'Selected Dates'}: {getDates()}
        </Typography>
      </Grid>
    </Grid>
  ) : null
}

export default SelectedTimeline
