import { createContext, Dispatch, SetStateAction, useState } from 'react'
import { useCheckAuthentication } from '../helpers/misc-functions'

export const AuthenticationContext = createContext<{
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>
  isLoggedIn: boolean
}>({
  setIsLoggedIn: () => null,
  isLoggedIn: false,
})

export const AuthenticationProvider = ({ children }: { children: React.ReactNode }) => {

  const [isLoggedIn, setIsLoggedIn] = useCheckAuthentication();

  return <AuthenticationContext.Provider value={{ setIsLoggedIn, isLoggedIn }}>{children}</AuthenticationContext.Provider>
}

export default AuthenticationProvider
