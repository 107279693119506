import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ChartFilterButtons from '../../../components/chart-filter-buttons'
import GenericChart from '../../../components/chart'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import IconButton, { iconButtonClasses } from '@mui/material/IconButton'
import SelectedTimeline from '../../../components/selected-timeline'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import MUIDataTable from 'mui-datatables'
import { error } from 'console'
import { setAllFilterTypes, sanitizeSleepData, sanitizeSleepQualityData } from '../../../helpers/misc-functions'

  
  interface IProps {
    selectedUser: IUserInfo | null
    userDetailData: IUserDetailData | null
    panelType: PanelType
    filterType: IChartFilter
    showDailyFilter: boolean
  }
  
  const defaultChartOptions = {
    title: {
      text: '',
    },
    xAxis: {
      categories: [],
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },
    series: [
      {
        name: '',
        data: [],
      },
    ],
  }


  const GenericPanelContent: React.FC<IProps> = ({ selectedUser, userDetailData, panelType, filterType, showDailyFilter}) => {
    const [selectedFilter, setSelectedFilter] = useState<IChartFilter | null>('weekly')
    const [chartOptions, setChartOptions] = useState<any>(defaultChartOptions)
    const [dailyArrIndex, setDailyArrIndex] = useState<number>(0);
    const [weeklyArrStartIndex, setWeeklyArrStartIndex] = useState<number>(0);
    const [weeklyArrEndIndex, setWeeklyArrEndIndex] = useState<number>(0);
    const [formattedDailyArr, setFormattedDailyArr] = useState<any>([]);
    const [formattedWeeklyArr, setFormattedWeeklyArr] = useState<any>([]);
    const [filterButtonStartDate, setFilterButtonStartDate] = useState<string | null>(null)
    const [filterButtonEndDate, setFilterButtonEndDate] = useState<string | null>(null)
    const [displayLeftArrow, setDisplayLeftArrow] = useState<boolean | null>(false);
    const [displayRightArrow, setDisplayRightArrow] = useState<boolean | null>(false);
    const [singleDayArrowsUsed, setSingleDayArrowsUsed] = useState<string | null>(null)
    const [singleDayDecrementDays, setSingleDayDecrementDays] = useState<boolean | null>(null)
    const [dateRangeArrowsBeginDate, setDateRangeArrowsBeginDate] = useState<string | null>(null)
    const [dateRangeArrowsEndDate, setDateRangeArrowsEndDate] = useState<string | null>(null)
    const [dateRangeDecrementDays, setDateRangeDecrementDays] = useState<boolean | null>(null)
    const [tableData, setTableData] = useState<any[]>([])
    // const [showDailyFilter, setShowDailyFilter] = useState<boolean | null> (true)
  
    // These state variables belong to the custom filter
    const [startDateValue, setStartDateValue] = useState<Date | any>(null)
    const [endDateValue, setEndDateValue] = useState<Date | any>(null)
    const [numberOfUnits, setNumberOfUnits] = useState<number | null>(0)
    const [avgType, setAvgType] = useState<string | null>('')
    const [btnPressed, setBtnPressed] = useState<boolean | null>(null)

    // Number of days to show on weekly chart
    const numOfWeeklyDays = 28
    let tableColumns: any[] = []
    let tableOptions: any = {}

    if (panelType === 'womens-health'){
      tableColumns = [
        { name: 'Due Date', options: { filter: false, sort: true } },
        { name: 'Original Due Date', options: { filter: false, sort: true } },
        { name: 'Period Length', options: { filter: false, sort: true } },
        { name: 'Current Phase', options: { filter: false, sort: true } },
        { name: 'Current Phase Type', options: { filter: false, sort: true } },
        { name: 'Length Of Current Phase', options: { filter: false, sort: true } },
        { name: 'Cycle Length', options: { filter: false, sort: true } },
        { name: 'Predicted Cycle Length', options: { filter: false, sort: false } },
        { name: 'Is Predicted Cycle', options: { filter: false, sort: true } },
        { name: 'Pregnancy Cycle Start Date', options: { filter: false, sort: true } },
      ]
      tableOptions = {
        viewColumns: false,
        filter: false,
        elevation: 0,
        selectableRows: 'none' as 'none',
        setTableProps: () => {
          return {
            size: 'small',
          }
        },
      }
    } else if (panelType === 'blood-pressure'){

      tableColumns = [
        { name: 'Measurement Time', options: { filter: false, sort: true } },
        { name: 'Systolic', options: { filter: false, sort: true } },
        { name: 'Diastolic', options: { filter: false, sort: true } },
        { name: 'Source Type', options: { filter: false, sort: true } },
        { name: 'Pulse', options: { filter: false, sort: true } },
        { name: 'MAP', options: { filter: false, sort: true } }
      ]
    
      tableOptions = {
        textLabels: {
          body: {
            noMatch: "No data available for the date selected",
          },
        },
        viewColumns: false,
        filter: false,
        elevation: 0,
        selectableRows: 'none' as 'none',
        setTableProps: () => {
          return {
            size: 'small',
          }
        },
      }
    } else if (panelType === 'sleep'){
      tableColumns = [
        { name: 'Date', options: { filter: false, sort: true } },
        { name: 'Total Sleep', options: { filter: false, sort: true } },
        { name: 'Deep', options: { filter: false, sort: true } },
        { name: 'Light', options: { filter: false, sort: true } },
        { name: 'REM', options: { filter: false, sort: true } },
        { name: 'Awake', options: { filter: false, sort: true } },
        { name: 'Sleep Quality Score', options: { filter: false, sort: true } }
      ]

      tableOptions = {
        textLabels: {
          body: {
            noMatch: "No data available for the date selected",
          },
        },
        viewColumns: false,
        filter: false,
        elevation: 0,
        selectableRows: 'none' as 'none',
        setTableProps: () => {
          return {
            size: 'small',
          }
        },
      }
    }


    // This is for checking the type of the data coming in when needed
    function isDailyHeartRateData(data: any): data is HeartRateData {
      return typeof data === 'object' && data !== null && 'timeoffsetheartratesamples' in data;
    }
    function isO2Data(data: any): data is O2Data {
      return typeof data === 'object' && data !== null && 'timeoffsetsleepspo2' in data;
    }
    function isStress(data: any): data is StressData {
      return typeof data === 'object' && data !== null && 'timeoffsetstresslevelvalues' in data;
    }
    function isBodyBattery(data: any): data is BodyBatteryData {
      return typeof data === 'object' && data !== null && 'timeoffsetbodybatteryvalues' in data;
    }
    function isIntensityMinutes(data: any): data is IntensityMinutesData {
      return typeof data === 'object' && data !== null && 'vigorousintensitydurationinseconds' in data && 'moderateintensitydurationinseconds' in data;
    }
    function isSleep(data: any): data is SleepData {
      return typeof data === 'object' && data !== null && 'sleeplevelsmap' in data;
    }
    function isStepsData(data: any): data is Steps {
      return data !== null && typeof data !== "string";
    }
    function isBloodPressure(data: any): data is BloodPressureMeasurement[] {
      return Array.isArray(data) && data !== null;
    }  
    function isInteger(value: any): boolean {
      return typeof value === 'number' && Number.isInteger(value);
  }

  const handleGetNext = () => {
    
    let lengthOfDataArr = 0;
    if (userDetailData) {

      // Using heart_rate daily since all data summaries have same
      // amount of days in their daily arrays
      lengthOfDataArr = Object.keys(userDetailData.heart_rate.daily).length            
    

      if (selectedFilter === 'daily') {

        if ((dailyArrIndex + 1) <= lengthOfDataArr - 1) {
          setDailyArrIndex(dailyArrIndex + 1);
          setDisplayRightArrow(true);
        } else {
          setDisplayRightArrow(false);
          console.log("Reached end of data set")
        }
      } else if (selectedFilter === 'weekly') {

        if ((weeklyArrEndIndex + numOfWeeklyDays) <= lengthOfDataArr - 1) {
          setWeeklyArrEndIndex(weeklyArrEndIndex + numOfWeeklyDays);
          setWeeklyArrStartIndex(weeklyArrStartIndex + numOfWeeklyDays);
          setDisplayRightArrow(true)
        } else {
          setWeeklyArrEndIndex(lengthOfDataArr - 1);
          setWeeklyArrStartIndex((lengthOfDataArr - 1) - numOfWeeklyDays)
          setDisplayRightArrow(false)
          console.log("Reached end of data set")
        }
      }
    }
  }

  const handleGetPrevious = () => {

    if (userDetailData) {

        if (selectedFilter === 'daily') {
            if ((dailyArrIndex - 1) >= 0) {
                setDailyArrIndex(dailyArrIndex - 1);
                setDisplayLeftArrow(true);
            }
            else {
                setDisplayLeftArrow(false);
                console.log("Reached beginning of data set")
            }
    
        } else if (selectedFilter === 'weekly') {
            if ((weeklyArrStartIndex - numOfWeeklyDays) >= 0) {
                setWeeklyArrStartIndex(weeklyArrStartIndex - numOfWeeklyDays);
                setWeeklyArrEndIndex(weeklyArrEndIndex - numOfWeeklyDays);            
                setDisplayLeftArrow(true)
            } else {
                setWeeklyArrStartIndex(0);
                setWeeklyArrEndIndex(numOfWeeklyDays);            
                setDisplayLeftArrow(false)
                console.log("Reached end of data set")
            }
        }
    }
  }
  

    ////
    // UseEffects
    ////
    
    // This sets the filter for all charts
    useEffect(() => {
        if (selectedUser && !selectedFilter && panelType!=='blood-pressure') {
        setSelectedFilter('weekly')
        } else if (selectedUser && panelType === 'blood-pressure'){
          setSelectedFilter('daily')
        }
    }, [selectedUser])

    // useEffect(() => {

    //   const newFilter = setAllFilterTypes(panelType, filterType)
    //   setSelectedFilter(filterType)

    //   console.log(`${panelType} panel: Global filter changed to ${filterType}, applied filter is ${newFilter}`);

    // }, [selectedFilter])
    
    // This preps the data for the panels
    useEffect(() => {
        if (userDetailData) {

          let dailyData: any[] = []

          if (panelType === 'heartrate'){
              // Use this function when mapping data
              dailyData = Object.keys(userDetailData.heart_rate.daily).map(key => {
                  const data = userDetailData.heart_rate.daily[key];
                  return { date: key,
                      data: isDailyHeartRateData(data) ? data : null };
              });
            } else if(panelType === 'o2-sats'){
              dailyData = Object.keys(userDetailData.o2.daily).map(key => {
                const data = userDetailData.o2.daily[key];
                return { date: key, data: isO2Data(data) ? data : null };
              });

            } else if(panelType === 'stress'){
              dailyData = Object.keys(userDetailData.stress.daily).map(key => {
                const data = userDetailData.stress.daily[key];
                return { date: key, data: isStress(data) ? data : null };
              });

            } else if(panelType === 'body-battery'){

              dailyData = Object.keys(userDetailData.body_battery.daily).map(key => {
                const data = userDetailData.body_battery.daily[key];
                return { date: key, data: isBodyBattery(data) ? data : null };
              });        

            } else if(panelType === 'intensity-minutes') {
              dailyData = Object.keys(userDetailData.intensity.daily).map(key => {
                const data = userDetailData.intensity.daily[key];
                return { date: key, data: isIntensityMinutes(data) ? data : null };
              });

            } else if(panelType === 'sleep') {
              dailyData = Object.keys(userDetailData.sleep.daily).map(key => {
                const data = userDetailData.sleep.daily[key];
                return { date: key, data: isSleep(data) ? data : null };
              });

            } else if(panelType === 'womens-health'){

            } else if(panelType === 'steps') {
              dailyData = Object.keys(userDetailData.steps.daily).map(key => {
                const data = userDetailData.steps.daily[key];
                return { date: key, data: isStepsData(data) ? data : null };
              });        

            } else if (panelType === 'blood-pressure'){
              dailyData = Object.keys(userDetailData.blood_pressure.daily).map(key => {
                let data = null;        
                if (userDetailData.blood_pressure.daily[key] !== "-") {
                  data = userDetailData.blood_pressure.daily[key]
                }
                return { date: key, data: data };
              });

            } else {
              console.error("Unsupported panel type provided: ", panelType)
          }

          if (dailyData.length !== 0){
            const selectedDayIndex = dailyData.length - 1
            const selectedDailyDate = dailyData[selectedDayIndex].date
            const selectedWeekEndIndex = dailyData.length - 1
            const selectedWeekStartIndex = selectedWeekEndIndex - numOfWeeklyDays
    
    
            // Adding 1 since the slice method is exclusive
            const weeklyData = dailyData.slice(selectedWeekStartIndex, selectedWeekEndIndex + 1)
    
            // Set up daily data
            setFormattedDailyArr(dailyData)
            setDailyArrIndex(selectedDayIndex)
    
            // This is for the display of the date in the panel for daily filter
            setSingleDayArrowsUsed(selectedDailyDate)
    
            // Set up weekly data
            setFormattedWeeklyArr(weeklyData)
            setWeeklyArrStartIndex(selectedWeekStartIndex)
            setWeeklyArrEndIndex(selectedWeekEndIndex)

          } else {
            console.debug("INFO: No daily data provided")
          }


        }
    }, [userDetailData])

    // Setting up charts for non 'custom' filters
    useEffect(() => {

        if (userDetailData) {

            if (!selectedFilter || formattedDailyArr.length === 0 || dailyArrIndex === null
            ) {
                setChartOptions(defaultChartOptions) 
            } else if (selectedFilter === 'daily') {

                if (panelType === 'heartrate'){

                    const dailyData = formattedDailyArr[dailyArrIndex]?.data;
                    const dailyDate = formattedDailyArr[dailyArrIndex]?.date;
                    setSingleDayArrowsUsed(dailyDate)

                    const categories = isDailyHeartRateData(dailyData)
                    ? Object.values(dailyData.timeoffsetheartratesamples).map((item: any) =>
                        new Date(parseInt(item?.timestamp) * 1000).toLocaleTimeString())
                    : [];
    
                    const series = isDailyHeartRateData(dailyData)
                    ? Object.values(dailyData.timeoffsetheartratesamples).map((item: any) => item?.value)
                    : [];
    
                    const chartDataForDaily = {
                    ...defaultChartOptions,
                    xAxis: {
                        ...defaultChartOptions.xAxis,
                        categories: categories
                    },
                    series: [
                        {
                        name: 'Heartrate',
                        data: series,
                        },
                    ],
                    }
    
                    setChartOptions(chartDataForDaily)
                  } else if(panelType === 'o2-sats'){
                    const dailyData = formattedDailyArr[dailyArrIndex]?.data;
                    const dailyDate = formattedDailyArr[dailyArrIndex]?.date;
                    setSingleDayArrowsUsed(dailyDate)

                    const categories = isO2Data(dailyData)
                    ? Object.values(dailyData.timeoffsetsleepspo2).map((item: any) =>
                      new Date(parseInt(item?.timestamp) * 1000).toLocaleTimeString())
                    : [];
          
                    const series = isO2Data(dailyData)
                      ? Object.values(dailyData.timeoffsetsleepspo2).map((item: any) => item?.value)
                      : [];
            
                    const chartDataForDaily = {
                      ...defaultChartOptions,
                      xAxis: {
                        ...defaultChartOptions.xAxis,
                        categories: categories
                      },
                      series: [
                        {
                          name: 'O2 Sats',
                          data: series,
                        },
                      ],
                    }
                    setChartOptions(chartDataForDaily)          

                  } else if(panelType === 'stress'){
                    const dailyData = formattedDailyArr[dailyArrIndex]?.data;
                    const dailyDate = formattedDailyArr[dailyArrIndex]?.date;
                    setSingleDayArrowsUsed(dailyDate)

                      const categories = isStress(dailyData)
                      ? Object.values(dailyData.timeoffsetstresslevelvalues).map((item: any) =>
                        new Date(parseInt(item?.timestamp) * 1000).toLocaleTimeString())
                      : [];
            
                    const series = isStress(dailyData)
                      ? Object.values(dailyData.timeoffsetstresslevelvalues).map((item: any) => item?.value)
                      : [];
            
                    const chartDataForDaily = {
                      ...defaultChartOptions,
                      xAxis: {
                        ...defaultChartOptions.xAxis,
                        categories: categories
                      },
                      series: [
                        {
                          name: 'Stress',
                          data: series,
                        },
                      ],
                    }        
            
                    setChartOptions(chartDataForDaily)
      
                  } else if(panelType === 'body-battery'){  
                    const dailyData = formattedDailyArr[dailyArrIndex]?.data;
                    const dailyDate = formattedDailyArr[dailyArrIndex]?.date;
                    setSingleDayArrowsUsed(dailyDate)                  
                      const categories = isBodyBattery(dailyData)
                      ? Object.values(dailyData.timeoffsetbodybatteryvalues).map((item: any) =>
                        new Date(parseInt(item?.timestamp) * 1000).toLocaleTimeString())
                      : [];
            
                    const series = isBodyBattery(dailyData)
                      ? Object.values(dailyData.timeoffsetbodybatteryvalues).map((item: any) => item?.value)
                      : [];
            
                    const chartDataForDaily = {
                      ...defaultChartOptions,
                      xAxis: {
                        ...defaultChartOptions.xAxis,
                        categories: categories
                      },
                      series: [
                        {
                          name: 'Body Battery',
                          data: series,
                        },
                      ],
                    }         
            
                    setChartOptions(chartDataForDaily)
      
                  } else if(panelType === 'intensity-minutes') {
                    // setShowDailyFilter(false)
                  } else if(panelType === 'sleep') {
                    // setShowDailyFilter(false)
                  } else if(panelType === 'womens-health'){
                    // setShowDailyFilter(false)
                    const womensHealth = userDetailData.women
                    const data: any[] = []
              
                    let isPredictedCycle: any = String(womensHealth.ispredictedcycle)
                    if (womensHealth.ispredictedcycle === true) {
                      isPredictedCycle = <CheckCircleIcon color="success" />
                    } else if (womensHealth.ispredictedcycle === false) {
                      isPredictedCycle = <CancelIcon color="error" />
                    }
              
                    data.push([
                      womensHealth.duedate,
                      womensHealth.originalduedate,
                      womensHealth.periodlength,
                      womensHealth.currentphase,
                      womensHealth.currentphasetype,
                      womensHealth.daysuntilnextphase,
                      womensHealth.cyclelength,
                      womensHealth.predictedcyclelength,
                      womensHealth.ispredictedcycle,
                      womensHealth.pregnancycyclestartdate
                    ])
                    setTableData(data)
      
                  } else if(panelType === 'steps') {
                    // setShowDailyFilter(false)
                  } else if (panelType === 'blood-pressure'){
                    // setShowDailyFilter(true)
                    const dailyData = formattedDailyArr[dailyArrIndex]?.data;
                    const dailyDate = formattedDailyArr[dailyArrIndex]?.date;
            
                    setSingleDayArrowsUsed(dailyDate)
            
                    const data: any[] = []
                    let localMeasurementTime;
            
                    if (dailyData) {
                      dailyData.forEach((item: any) => {
            
                        if (isInteger(item.measurementtime)){
                          const measurementTimeInMilli = item.measurementtime * 1000
                          
                          localMeasurementTime = new Date(measurementTimeInMilli).toLocaleTimeString()
                        } else {
                          localMeasurementTime = item.measurementtime
                        }           
            
                        data.push([
                          localMeasurementTime,
                          item.systolic,
                          item.diastolic,
                          item.sourceType,
                          item.pulse,
                          item.mapformula
                        ])
            
                      })
                    }
                    setTableData(data)
                  } else {
                    setTableData([])
                    setChartOptions(defaultChartOptions)
                    console.error("Unsupported panel type provided: ", panelType)
                }


            } else if (selectedFilter === 'weekly' && weeklyArrStartIndex !== null && weeklyArrEndIndex !== null) {
                const weeklyData = formattedDailyArr.slice(weeklyArrStartIndex, weeklyArrEndIndex + 1)

                // This is for the display of hte dates in the panel for weekly filter
                setFilterButtonStartDate(weeklyData[0].date)
                setFilterButtonEndDate(weeklyData[weeklyData.length - 1].date)

                if (panelType === 'heartrate'){
                    const categories = Object.values(weeklyData).map((item: any) => item.date)
                    const seriesAvgHeartrate = Object.values(weeklyData).map((item: any) => {
                        if (item.data) {
                            return item?.data?.averageheartrateinbeatsperminute
                        } else {
                            return null
                        }
                        })
        
                        const seriesMaxHeartrate = Object.values(weeklyData).map((item: any) => {
                        if (item.data) {
                            return item?.data?.maxheartrateinbeatsperminute
                        } else {
                            return null
                        }
                        })
        
                        const seriesRestingHeartrate = Object.values(weeklyData).map((item: any) => {
                        if (item.data) {
                            return item?.data?.restingheartrateinbeatsperminute
                        } else {
                            return null
                        }
                        })
        
                        const multidayChartData = {
                        title: {
                            text: '',
                        },
                        plotOptions: {
                            series: {
                            label: {
                                connectorAllowed: false,
                            }
                            },
                        },
                        xAxis: {
                            ...defaultChartOptions.xAxis,
                            categories: categories,
                        },
                        series: [
                            {
                            name: 'Average Heart Rate In Beats Per Minute',
                            data: seriesAvgHeartrate,
                            },
                            {
                            name: 'Resting Heart Rate In Beats Per Minute',
                            data: seriesRestingHeartrate,
                            },
                            {
                            name: 'Max Heart Rate In Beats Per Minute',
                            data: seriesMaxHeartrate,
                            },
                        ],
                        }
                        setChartOptions(multidayChartData)
                      } else if(panelType === 'o2-sats'){

                        const categories = Object.values(weeklyData).map((item: any) => item.date)

                        const seriesLowO2 = Object.values(weeklyData).map((item: any) => {
                          if (item.data) {
                            return item?.data?.low
                          } else {
                            return null
                          }
                        })
                
                        const seriesAvgO2 = Object.values(weeklyData).map((item: any) => {
                          if (item.data) {
                            return item?.data?.avg
                          } else {
                            return null
                          }
                        })
                
                        const multidayChartData = {
                          title: {
                            text: '',
                          },
                          plotOptions: {
                            series: {
                              label: {
                                connectorAllowed: false,
                              }
                            },
                          },
                          xAxis: {
                            ...defaultChartOptions.xAxis,
                            categories: categories,
                          },
                          series: [
                            {
                              name: 'Low',
                              data: seriesLowO2,
                            },
                            {
                              name: 'Average',
                              data: seriesAvgO2,
                            },
                          ],
                        }
                        setChartOptions(multidayChartData)

                      } else if(panelType === 'stress'){
                        const categories = Object.values(weeklyData).map((item: any) => item.date)
                        const seriesAvgStressLevel = Object.values(weeklyData).map((item: any) => {
                          if (item.data) {
                            return item?.data?.averagestresslevel
                          } else {
                            return null
                          }
                        })

                        const multidayChartData = {
                          title: {
                            text: '',
                          },
                          plotOptions: {
                            series: {
                              label: {
                                connectorAllowed: false,
                              }
                            },
                          },
                          xAxis: {
                            ...defaultChartOptions.xAxis,
                            categories: categories,
                          },
                          series: [
                            {
                              name: 'Average Stress Level',
                              data: seriesAvgStressLevel,
                            },
                          ],
                        }
                        setChartOptions(multidayChartData)
          
                      } else if(panelType === 'body-battery'){
                              
                        const categories = Object.values(weeklyData).map((item: any) => item.date)
                        const seriesBodyBatteryHigh = Object.values(weeklyData).map((item: any) => {
                          if (item.data) {
                            return item?.data?.high
                          } else {
                            return null
                          }
                        })
                        const seriesBodyBatteryLow = Object.values(weeklyData).map((item: any) => {
                          if (item.data) {
                            return item?.data?.low
                          } else {
                            return null
                          }
                        })
                        const multidayChartData = {
                          title: {
                            text: '',
                          },
                          plotOptions: {
                            series: {
                              label: {
                                connectorAllowed: false,
                              }
                            },
                          },
                          xAxis: {
                            ...defaultChartOptions.xAxis,
                            categories: categories,
                          },
                          series: [
                            {
                              name: 'High',
                              data: seriesBodyBatteryHigh,
                            },
                            {
                              name: 'Low',
                              data: seriesBodyBatteryLow
                            }
                          ],
                        }
                        setChartOptions(multidayChartData)
                
                      } else if(panelType === 'intensity-minutes') {
                        
                        const categories = Object.values(weeklyData).map((item: any) => item.date)
                        const seriesIntensityMinutesVigorous = Object.values(weeklyData).map((item: any) => {
                          if (item.data) {
                            return item?.data?.vigorousintensitydurationinseconds
                          } else {
                            return null
                          }
                        })
                        const seriesIntensityMinutesModerate = Object.values(weeklyData).map((item: any) => {
                          if (item.data) {
                            return item?.data?.moderateintensitydurationinseconds
                          } else {
                            return null
                          }
                        })


                        const multidayChartData = {
                          title: {
                            text: '',
                          },
                          plotOptions: {
                            series: {
                              label: {
                                connectorAllowed: false,
                              }
                            },
                          },
                          xAxis: {
                            ...defaultChartOptions.xAxis,
                            categories: categories,
                          },
                          series: [
                            {
                              name: 'Vigorous',
                              data: seriesIntensityMinutesVigorous,
                            },
                            {
                              name: 'Moderate',
                              data: seriesIntensityMinutesModerate
                            }
                          ],
                        }
                        setChartOptions(multidayChartData)
                      } else if(panelType === 'sleep') {

                        const categories = Object.values(weeklyData).map((item: any) => item.date)                
                        const seriesDeepSleep = Object.values(weeklyData).map((item: any) => {
                          if (item.data && item.data.deepsleepdurationinseconds != "-") {
                            return { 
                              y: item?.data?.deepsleepdurationinhours,
                              additionalField: item?.data?.deepsleepduration
                            }          
                
                          } else {
                            return { 
                              y: 0,
                              additionalField: "-"
                            }   
                          }
                        })
                
                        const seriesLightSleep = Object.values(weeklyData).map((item: any) => {
                          if (item.data && item.data.lightsleepdurationinseconds !== "-") {
                            return { 
                              y: item?.data?.lightsleepdurationinhours,
                              additionalField: item?.data?.lightsleepduration
                            } 
                          } else {
                            return { 
                              y: 0,
                              additionalField: "-"
                            }   
                          }
                        })
                        const seriesREMSleep = Object.values(weeklyData).map((item: any) => {
                          if (item.data && item.data.remsleepdurationinseconds !== "-") {
                            return { 
                             y: item?.data?.remsleepdurationinhours,
                             additionalField: item?.data?.remsleepduration
                            }
                          } else {
                            return { 
                              y: 0,
                              additionalField: "-"
                            }   
                          }
                        })
                        const seriesAwakeDuration = Object.values(weeklyData).map((item: any) => {
                
                          if (item.data && item.data.awakedurationinseconds !== "-") {
                
                            return { 
                              y: item?.data?.awakedurationinhours,
                              additionalField: item?.data?.awakeduration
                            }
                          } else {
                            return { 
                              y: 0,
                              additionalField: "-"
                            }   
                          }
                        })

                        const seriesSleepQuality = Object.values(weeklyData).map((item: any) => {
                          if (item.data) {
                            return item?.data?.sleepquality
                          } else {
                            return null
                          }
                        })                                  
                        
                        const multidaySleepChartData = {
                          title: {
                            text: 'Sleep Hours & Quality',
                          },
                          chart: {
                            type: 'column',
                          },
                          plotOptions: {
                            column: {
                              stacking: 'normal',
                              dataLabels: {
                                enabled: false,
                              },
                            },
                          },
                          xAxis: {
                            ...defaultChartOptions.xAxis,
                            categories: categories,
                          },
                          yAxis: [{
                            min: 0,
                            title: {
                              text: 'Hours'
                            }
                          }, {
                            title: {
                              text: 'Quality'
                            },
                            opposite: true,
                            min: 0,
                            max: 100
                          }],
                          legend: {
                            shadow: false
                          },
                          tooltip: {
                            formatter: function(this: Highcharts.TooltipFormatterContextObject): string {
                              const value = this.y;
                              const additionalData = (this.point as any).additionalField;
                              if (typeof value !== 'number') {
                                return `${this.series.name}: N/A`;
                              }
                              if (this.series.name === 'Quality') {
                                return `${this.series.name}: ${value.toFixed(2)}%`;
                              }
                              return `${this.series.name}: ${additionalData}`;
                            }
                          },
                          series: [
                            {
                              name: 'Deep',
                              data: sanitizeSleepData(seriesDeepSleep, 'Deep'),
                              stack: 'hours'
                            },
                            {
                              name: 'Light',
                              data: sanitizeSleepData(seriesLightSleep, 'Light'),
                              stack: 'hours'
                            },
                            {
                              name: 'REM',
                              data: sanitizeSleepData(seriesREMSleep, 'REM'),
                              stack: 'hours'
                            },
                            {
                              name: 'Awake',
                              data: sanitizeSleepData(seriesAwakeDuration, 'Awake'),
                              stack: 'hours'
                            },
                            {
                              name: 'Quality',
                              type: 'spline',
                              data: sanitizeSleepQualityData(seriesSleepQuality),
                              yAxis: 1,
                              tooltip: {
                                valueSuffix: '%'
                              }
                            }
                          ],
                        };
                        setChartOptions(multidaySleepChartData)
          
                      } else if(panelType === 'womens-health'){
          
                      } else if(panelType === 'steps') {
                        const categories = weeklyData.map((item: any) => {
                          if (item.date) {
                            return item?.date
                          } else {
                            return null
                          }
                        })
                
                        const seriesSteps = Object.values(weeklyData).map((item: any) => {
                          if (item.data) {
                            return item?.data
                          } else {
                            return null
                          }
                        })
                
                
                        const multidayChartData = {
                          title: {
                            text: '',
                          },
                          plotOptions: {
                            series: {
                              label: {
                                connectorAllowed: false,
                              }
                            },
                          },
                          xAxis: {
                            ...defaultChartOptions.xAxis,
                            categories: categories,
                          },
                          series: [
                            {
                              name: 'Steps',
                              data: seriesSteps,
                            },
                          ],
                        }
                        setChartOptions(multidayChartData)
          
                      } else if (panelType === 'blood-pressure'){
          
                      } else {
                    setChartOptions(defaultChartOptions)
                    console.error("Unsupported panel type provided: ", panelType)
                }                
                
            }
            else if (selectedFilter === 'monthly') {

                if (panelType === 'heartrate'){

                    const categories = Object.keys(userDetailData.heart_rate.monthly)
                    const monthlyHeartRateAvgValues = Object.values(userDetailData.heart_rate.monthly)
                    

                    setFilterButtonStartDate(categories[0])
                    setFilterButtonEndDate(categories[categories.length - 1])

                    const multidayChartData = {
                    title: {
                        text: '',
                    },
                    plotOptions: {
                        series: {
                        label: {
                            connectorAllowed: false,
                        },
                        },
                    },
                    xAxis: {
                        ...defaultChartOptions.xAxis,
                        categories: categories,
                    },
                    series: [
                        {
                        name: 'Resting Heart Rate In Beats Per Minute',
                        data: monthlyHeartRateAvgValues
                        }
                    ],
                    }
                    setChartOptions(multidayChartData)

                  } else if(panelType === 'o2-sats'){                    
                                
                    const monthlyO2AvgValues = Object.values(userDetailData.o2.monthly)
                    const monthlyO2AvgKeys = Object.keys(userDetailData.o2.monthly)

                    setFilterButtonStartDate(monthlyO2AvgKeys[0])
                    setFilterButtonEndDate(monthlyO2AvgKeys[monthlyO2AvgKeys.length - 1])

                    const multidayChartData = {
                      title: {
                        text: '',
                      },
                      plotOptions: {
                        series: {
                          label: {
                            connectorAllowed: false,
                          },
                        },
                      },
                      xAxis: {
                        ...defaultChartOptions.xAxis,
                        categories: monthlyO2AvgKeys,
                      },
                      series: [
                        {
                          name: 'Average',
                          data: monthlyO2AvgValues
                        }
                      ],
                    }
                    setChartOptions(multidayChartData)

                  } else if(panelType === 'stress'){

                      const monthlyAvgStressValues = Object.values(userDetailData.stress.monthly)
                      const monthlyAvgStressKeys = Object.keys(userDetailData.stress.monthly)
              
                      setFilterButtonStartDate(monthlyAvgStressKeys[0])
                      setFilterButtonEndDate(monthlyAvgStressKeys[monthlyAvgStressKeys.length - 1])
              
                      const multidayChartData = {
                        title: {
                          text: '',
                        },
                        plotOptions: {
                          series: {
                            label: {
                              connectorAllowed: false,
                            },
                          },
                        },
                        xAxis: {
                          ...defaultChartOptions.xAxis,
                          categories: monthlyAvgStressKeys,
                        },
                        series: [
                          {
                            name: 'Avg Stress',
                            data: monthlyAvgStressValues
                          }
                        ],
                      }
                      setChartOptions(multidayChartData)
      
                  } else if(panelType === 'body-battery'){
                                
                    const monthlyBodyBatteryHighKeys = Object.keys(userDetailData.monthly_avg_body_battery_high.monthly)
                    const monthlyBodyBatteryHighValues = Object.values(userDetailData.monthly_avg_body_battery_high.monthly)
                    const monthlyBodyBatteryLowValues = Object.values(userDetailData.monthly_avg_body_battery_low.monthly)

                    setFilterButtonStartDate(monthlyBodyBatteryHighKeys[0])
                    setFilterButtonEndDate(monthlyBodyBatteryHighKeys[monthlyBodyBatteryHighKeys.length - 1])

                    const multidayChartData = {
                      title: {
                        text: '',
                      },
                      plotOptions: {
                        series: {
                          label: {
                            connectorAllowed: false,
                          },
                        },
                      },
                      xAxis: {
                        ...defaultChartOptions.xAxis,
                        categories: monthlyBodyBatteryHighKeys,
                      },
                      series: [
                        {
                          name: 'Average High',
                          data: monthlyBodyBatteryHighValues
                        },
                        {
                          name: 'Average Low',
                          data: monthlyBodyBatteryLowValues
                        }

                      ],
                    }
                    setChartOptions(multidayChartData)

      
                  } else if(panelType === 'intensity-minutes') {
                    
                    const monthlyVigorousIntensityMinutesKeys = Object.keys(userDetailData.intensity_vigorous.monthly)
                    const monthlyModerateIntensityMinutesValues = Object.values(userDetailData.intensity_moderate.monthly)
                    const monthlyVigoruousIntensityMinutesValues = Object.values(userDetailData.intensity_vigorous.monthly)

                    setFilterButtonStartDate(monthlyVigorousIntensityMinutesKeys[0])
                    setFilterButtonEndDate(monthlyVigorousIntensityMinutesKeys[monthlyVigorousIntensityMinutesKeys.length - 1])

                    const multidayChartData = {
                      title: {
                        text: '',
                      },
                      plotOptions: {
                        series: {
                          label: {
                            connectorAllowed: false,
                          },
                        },
                      },
                      xAxis: {
                        ...defaultChartOptions.xAxis,
                        categories: monthlyVigorousIntensityMinutesKeys,
                      },
                      series: [
                        {
                          name: 'Average Vigorous Intensity Minutes',
                          data: monthlyVigoruousIntensityMinutesValues
                        },
                        {
                          name: 'Average Moderate Intensity Minutes',
                          data: monthlyModerateIntensityMinutesValues
                        }

                      ],
                    }
                    setChartOptions(multidayChartData)
                  } else if(panelType === 'sleep') {
                    const monthlySleepQualityKeys = Object.keys(userDetailData.monthly_avg_sleepquality.monthly)
                    const monthlySleepQualityValues = Object.values(userDetailData.monthly_avg_sleepquality.monthly)
            
                    const monthlySleepHoursAvgKeys = Object.keys(userDetailData.sleep.monthly)
                    const monthlySleepHoursAvgValues = Object.values(userDetailData.sleep.monthly)
                    setFilterButtonStartDate(monthlySleepQualityKeys[0])
                    setFilterButtonEndDate(monthlySleepQualityKeys[monthlySleepQualityKeys.length - 1])

                    const multidayChartData = {
                      title: {
                        text: '',
                      },
                      plotOptions: {
                        series: {
                          label: {
                            connectorAllowed: false,
                          },
                        },
                      },
                      xAxis: {
                        ...defaultChartOptions.xAxis,
                        categories: monthlySleepQualityKeys,
                      },
                      series: [
                        {
                          name: 'Average Sleep Quality',
                          data: monthlySleepQualityValues
                        },
                        {
                          name: 'Average Sleep Hours',
                          data: monthlySleepHoursAvgValues
                        }
                      ],
                    }
                    setChartOptions(multidayChartData)
      
                  } else if(panelType === 'womens-health'){
      
                  } else if(panelType === 'steps') {
                    const monthlyStepsAvgValues = Object.values(userDetailData.steps.monthly)
                    const monthlyStepsAvgKeys = Object.keys(userDetailData.steps.monthly)
            
                    setFilterButtonStartDate(monthlyStepsAvgKeys[0])
                    setFilterButtonEndDate(monthlyStepsAvgKeys[monthlyStepsAvgKeys.length - 1])
            
                    const multidayChartData = {
                      title: {
                        text: '',
                      },
                      plotOptions: {
                        series: {
                          label: {
                            connectorAllowed: false,
                          },
                        },
                      },
                      xAxis: {
                        ...defaultChartOptions.xAxis,
                        categories: monthlyStepsAvgKeys,
                      },
                      series: [
                        {
                          name: 'Average Steps',
                          data: monthlyStepsAvgValues
                        }
                      ],
                    }
                    setChartOptions(multidayChartData)
      
                  } else if (panelType === 'blood-pressure'){
      
                  } else {
                    setChartOptions(defaultChartOptions)
                    console.error("Unsupported panel type provided: ", panelType)
                }   

            }

            // Set up Table Data based on Panel Type

            if (panelType === 'sleep'){
              const data: any[] = []
              let reversedFormattedDailyArr: any[] = formattedDailyArr.slice().reverse();
        
              reversedFormattedDailyArr.forEach((item: any) => {
                if (item.data) {
                  data.push([
                    item.date,
                    item.data.totalsleepduration,
                    item.data.deepsleepduration,
                    item.data.lightsleepduration,
                    item.data.remsleepduration,
                    item.data.awakeduration,
                    String(item.data.sleepquality) + "%",
                  ])
                }
              })
              setTableData(data)
            } else if(panelType === 'womens-health'){
              const womensHealth = userDetailData.women
              const data: any[] = []
        
              let isPredictedCycle: any = String(womensHealth.ispredictedcycle)
              if (womensHealth.ispredictedcycle === true) {
                isPredictedCycle = <CheckCircleIcon color="success" />
              } else if (womensHealth.ispredictedcycle === false) {
                isPredictedCycle = <CancelIcon color="error" />
              }
        
              data.push([
                womensHealth.duedate,
                womensHealth.originalduedate,
                womensHealth.periodlength,
                womensHealth.currentphase,
                womensHealth.currentphasetype,
                womensHealth.daysuntilnextphase,
                womensHealth.cyclelength,
                womensHealth.predictedcyclelength,
                womensHealth.ispredictedcycle,
                womensHealth.pregnancycyclestartdate
              ])
              setTableData(data)
            }

            

        } else {
            setChartOptions(defaultChartOptions)
        }
    }, [userDetailData, formattedDailyArr, weeklyArrStartIndex, weeklyArrEndIndex, dailyArrIndex, selectedFilter, singleDayArrowsUsed])

  
    // Implement navigation logic (handleGetNext, handleGetPrevious)
    useEffect(() => {

        let lengthOfDataArr = 0;
        if (userDetailData) {

          // Using heart_rate daily since all data summaries have same
          // amount of days in their daily arrays
          if (panelType !== 'blood-pressure'){
            lengthOfDataArr = Object.keys(userDetailData.heart_rate.daily).length            
          } else if (panelType === 'blood-pressure') {
            lengthOfDataArr = Object.keys(userDetailData.blood_pressure.daily).length
          } else {
            console.error("INFO: Unknown panel type in navigation logic")
          }
          


          if (selectedFilter === 'daily') {
            if ((dailyArrIndex >= (lengthOfDataArr - 1))) {
              setDisplayRightArrow(false)
            } else {
              setDisplayRightArrow(true)
            }
    
            if ((dailyArrIndex <= 0)) {
              setDisplayLeftArrow(false)
            } else {
              setDisplayLeftArrow(true)
            }
          }
          else if (selectedFilter === 'weekly') {
            if (weeklyArrEndIndex >= (lengthOfDataArr - 1)) {
              setDisplayRightArrow(false)
            } else {
              setDisplayRightArrow(true)
            }
    
            if (weeklyArrStartIndex <= 0) {
              setDisplayLeftArrow(false)
            } else {
              setDisplayLeftArrow(true)
            }
          }
    
        }
    
      }, [dailyArrIndex, weeklyArrStartIndex, weeklyArrEndIndex, displayLeftArrow, displayRightArrow, selectedFilter])

    return (
      <>
        {/* Render SelectedTimeline, ChartFilterButtons, and Chart components */}

        {!selectedUser ? (
        <Typography variant="body2">Please search for user first</Typography>
      ) : (
        <>

          {
            panelType !== 'womens-health' && panelType !== 'blood-pressure' && (
              <SelectedTimeline
              selectedFilter={selectedFilter}
              customRangeStartDate={startDateValue}
              customRangeEndDate={endDateValue}
              dateRangeStartDate={filterButtonStartDate ? filterButtonStartDate : dateRangeArrowsBeginDate}
              dateRangeEndDate={filterButtonEndDate ? filterButtonEndDate : dateRangeArrowsEndDate}
              singleDay={singleDayArrowsUsed}
            />
            )
          }

          {
            panelType !== 'womens-health' && panelType !== 'blood-pressure' && (
              <ChartFilterButtons
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              setSingleDayArrowsUsed={setSingleDayArrowsUsed}
              setDateRangeArrowsBeginDate={setDateRangeArrowsBeginDate}
              setDateRangeArrowsEndDate={setDateRangeArrowsEndDate}
              startDateValue={startDateValue}
              setStartDateValue={setStartDateValue}
              endDateValue={endDateValue}
              setEndDateValue={setEndDateValue}
              setSingleDayDecrementDays={setSingleDayDecrementDays}
              setDateRangeDecrementDays={setDateRangeDecrementDays}
              setFilterButtonStartDate={setFilterButtonStartDate}
              setFilterButtonEndDate={setFilterButtonEndDate}
              setChartData={setChartOptions}
              showDailyFilter={showDailyFilter}
              showWeeklyFilter={true}
              showMonthlyFilter={true}
              showCustomFilter={false}
              numberOfUnits={numberOfUnits}
              setNumberOfUnits={setNumberOfUnits}
              avgType={avgType}
              setAvgType={setAvgType}
              btnPressed={btnPressed}
              setBtnPressed={setBtnPressed}
            />
            )
          }

          {/* Exclude Panel Arrows and chart from Women's Health Panel, but include for all other panels */}
          {
            panelType !== 'womens-health' && panelType !== 'blood-pressure' && (
              <Grid container={true} alignItems="center" justifyContent="center" spacing={2} sx={{ marginTop: '10px' }}>
              {selectedFilter !== 'custom' && selectedFilter !== 'monthly' && (
                <Grid item={true} xs={1}>
                  <IconButton onClick={handleGetPrevious}
                    style={{ visibility: displayLeftArrow ? 'visible' : 'hidden' }}>
                    <ArrowBackIosIcon />
                  </IconButton>
                </Grid>
              )}
  
              <Grid item={true} xs={10}>
                  <GenericChart chartOptions={chartOptions} />
              </Grid>
  
              {selectedFilter !== 'custom' && selectedFilter !== 'monthly' && (
                <Grid item={true} xs={1}>
                  <IconButton onClick={handleGetNext}
                    style={{ visibility: displayRightArrow ? 'visible' : 'hidden' }}>
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            )
          }
          {
            panelType === 'sleep' && selectedFilter !== 'custom' && (
              <MUIDataTable title="" data={tableData} columns={tableColumns} options={tableOptions} />
            )
          }

          {/* Women's Health Panel */}
          {
            panelType === 'womens-health' && (
              <MUIDataTable title="Women's Health Data" data={tableData} columns={tableColumns} options={tableOptions} />
            )
          }

          {/* Blood Pressure Panel */}
          {
            panelType === 'blood-pressure' && (
              <SelectedTimeline
              selectedFilter={selectedFilter}
              customRangeStartDate={startDateValue}
              customRangeEndDate={endDateValue}
              dateRangeStartDate={filterButtonStartDate ? filterButtonStartDate : dateRangeArrowsBeginDate}
              dateRangeEndDate={filterButtonEndDate ? filterButtonEndDate : dateRangeArrowsEndDate}
              singleDay={singleDayArrowsUsed}
            />
            )
          }
          {panelType === 'blood-pressure' && (
              <ChartFilterButtons
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              setSingleDayArrowsUsed={setSingleDayArrowsUsed}
              setDateRangeArrowsBeginDate={setDateRangeArrowsBeginDate}
              setDateRangeArrowsEndDate={setDateRangeArrowsEndDate}
              startDateValue={startDateValue}
              setStartDateValue={setStartDateValue}
              endDateValue={endDateValue}
              setEndDateValue={setEndDateValue}
              setSingleDayDecrementDays={setSingleDayDecrementDays}
              setDateRangeDecrementDays={setDateRangeDecrementDays}
              setFilterButtonStartDate={setFilterButtonStartDate}
              setFilterButtonEndDate={setFilterButtonEndDate}
              setChartData={setChartOptions}
              showDailyFilter={showDailyFilter}
              showWeeklyFilter={false}
              showMonthlyFilter={false}
              showCustomFilter={false}
              numberOfUnits={numberOfUnits}
              setNumberOfUnits={setNumberOfUnits}
              avgType={avgType}
              setAvgType={setAvgType}
              btnPressed={btnPressed}
              setBtnPressed={setBtnPressed}
            />
            )}
          { panelType === 'blood-pressure' && (

            <Grid container={true} alignItems="center" justifyContent="center" spacing={2} sx={{ marginTop: '10px' }}>
            { (
              <Grid item={true} xs={1}>
                <IconButton onClick={handleGetPrevious}
                  style={{ visibility: displayLeftArrow ? 'visible' : 'hidden' }}>
                  <ArrowBackIosIcon />
                </IconButton>
              </Grid>
            )}

              {                
                <MUIDataTable title="Blood Pressure Data" data={tableData} columns={tableColumns} options={tableOptions} />                
              }

            {(
              <Grid item={true} xs={1}>
                <IconButton onClick={handleGetNext}
                  style={{ visibility: displayRightArrow ? 'visible' : 'hidden' }}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Grid>
            )}
            </Grid>
            )
          }
        </>
      )}

      </>
    )
  }
  
  export default GenericPanelContent