import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_exporting from 'highcharts/modules/exporting'
import { useEffect, useRef, useState} from 'react'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display'

const Chart = ({ chartOptions }: { chartOptions: any }) => {
  const chart: any = useRef(null)
  const [key, setKey] = useState(0);

  // init Highcharts exporting module
  HC_exporting(Highcharts)

  NoDataToDisplay(Highcharts)
  Highcharts.setOptions({
    lang: {
      noData: 'There is no data for the selected time period',
    },
  })

  useEffect(() => {

    setKey(prevKey => prevKey + 1);

  }, [chartOptions]);
  

  return <HighchartsReact key={key} ref={chart} highcharts={Highcharts} options={chartOptions} />
}

export default Chart
