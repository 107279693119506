import { Grid, Typography } from '@mui/material'
import FormikInput from '../../../components/formik-input'
import FormikSelect from '../../../components/formik-select'

const IntensityMinutes = () => {
  return (
    <Grid item={true} xs={12} container={true} alignItems="center" spacing={3}>
      <Grid item={true} xs={2}>
        <Typography variant="subtitle1">Intensity Minutes</Typography>
      </Grid>

      <Grid item={true} xs={2}>
        <Typography variant="subtitle1">is greater than or equal to</Typography>
      </Grid>

      <Grid item={true} xs={2}>
        <FormikInput name="intensityMinutes.greaterThanOrEqualMinutes" inputLabel="Minutes" size="small" type="number" />
      </Grid>

      <Grid item={true} xs={2}>
        <Typography variant="subtitle1">within the past</Typography>
      </Grid>

      <Grid item={true} xs={3}>
        <FormikSelect name="intensityMinutes.timePeriod" dropdownItems={['24 hours', '48 hours', '72 hours']} sx={{ marginTop: '1px' }} />
      </Grid>
    </Grid>
  )
}

export default IntensityMinutes
