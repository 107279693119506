import React from 'react';
import { Button, Grid, TextField, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { grey } from '@mui/material/colors';
import { Dispatch, SetStateAction, useState } from 'react'


interface IProps {
  selectedFilter: IChartFilter | null
  setSelectedFilter: Dispatch<SetStateAction<IChartFilter | null>>
  setSingleDayArrowsUsed: Dispatch<SetStateAction<string | null>>
  setDateRangeArrowsBeginDate: Dispatch<SetStateAction<string | null>>
  setDateRangeArrowsEndDate: Dispatch<SetStateAction<string | null>>
  setStartDateValue: Dispatch<SetStateAction<Date | null>>
  setEndDateValue: Dispatch<SetStateAction<Date | null>>
  startDateValue: Date | null
  endDateValue: Date | null
  setSingleDayDecrementDays: Dispatch<SetStateAction<boolean | null>>
  setDateRangeDecrementDays: Dispatch<SetStateAction<boolean | null>>
  setFilterButtonStartDate: Dispatch<SetStateAction<string | null>>
  setFilterButtonEndDate: Dispatch<SetStateAction<string | null>>
  setChartData?: any
  showDailyFilter: Boolean | null
  showWeeklyFilter: Boolean | null
  showMonthlyFilter: Boolean | null
  showCustomFilter: Boolean | null
  numberOfUnits: number | null
  setNumberOfUnits: Dispatch<SetStateAction<number | null>>
  avgType: string | null
  setAvgType: Dispatch<SetStateAction<string | null>>
  btnPressed: Boolean | null
  setBtnPressed: Dispatch<SetStateAction<boolean | null>>
}

const defaultChartData = {
  title: {
    text: '',
  },
  xAxis: {
    categories: [],
  },
  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
    },
  },
  series: [
    {
      name: '',
      data: [],
    },
  ],
}

const ChartFilterButtons = (props: IProps) => {
  const {
    selectedFilter,
    setSelectedFilter,
    setSingleDayArrowsUsed,
    setDateRangeArrowsBeginDate,
    setDateRangeArrowsEndDate,
    setStartDateValue,
    setEndDateValue,
    startDateValue,
    endDateValue,
    setSingleDayDecrementDays,
    setDateRangeDecrementDays,
    setFilterButtonStartDate,
    setFilterButtonEndDate,
    setChartData,
    showDailyFilter,
    showWeeklyFilter,
    showMonthlyFilter,
    showCustomFilter,
    numberOfUnits,
    setNumberOfUnits,
    avgType,
    setAvgType,
    btnPressed,
    setBtnPressed
  } = props

  const handleNonCustomClick = (e: React.MouseEvent, filterValue: IChartFilter) => {
    e.preventDefault(); // Prevent default button behavior
    setSelectedFilter(filterValue);

    setSingleDayArrowsUsed(null);
    setDateRangeArrowsBeginDate(null);
    setDateRangeArrowsEndDate(null);

    setStartDateValue(null);
    setEndDateValue(null);

    if (filterValue === 'daily') {
      setDateRangeDecrementDays(null);
      setFilterButtonStartDate(null);
      setFilterButtonEndDate(null);
    }
    if (filterValue === 'weekly' || filterValue === 'monthly') {
      setSingleDayDecrementDays(null);
      if (setChartData) {
        setChartData(defaultChartData);
      }
    }
  };

  const handleCustomClick = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent default button behavior
    setSelectedFilter('custom');

    setDateRangeDecrementDays(null);
    setSingleDayDecrementDays(null);
    setFilterButtonStartDate(null);
    setFilterButtonEndDate(null);

    if (setChartData) {
      setChartData(defaultChartData);
    }
  };

  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent default button behavior
    setBtnPressed(true);
  };

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault(); // Prevent default input behavior
    const value = parseInt(e.target.value);
    if (!isNaN(value)) {
      setNumberOfUnits(value);
    }
  };

  // Create filter buttons with proper event handling
  const dailyFilter = showDailyFilter && (
    <Button
      size="small"
      color="secondary"
      variant={selectedFilter === 'daily' ? 'outlined' : 'text'}
      onClick={(e) => handleNonCustomClick(e, 'daily')}
      sx={{
        marginRight: '10px',
        height: '40px',
        pointerEvents: selectedFilter === 'daily' ? 'none' : 'auto',
        color: selectedFilter === 'daily' ? grey[600] : 'secondary',
      }}
    >
      Daily
    </Button>
  );

  const weeklyFilter = showWeeklyFilter && (
    <Button
      size="small"
      color="secondary"
      variant={selectedFilter === 'weekly' ? 'outlined' : 'text'}
      onClick={(e) => handleNonCustomClick(e, 'weekly')}
      sx={{ 
        marginRight: '10px', 
        pointerEvents: selectedFilter === 'weekly' ? 'none' : 'auto', 
        color: selectedFilter === 'weekly' ? grey[600] : 'secondary' 
      }}
    >
      Weekly
    </Button>
  );

  const monthlyFilter = showMonthlyFilter && (
    <Button
      size="small"
      color="secondary"
      variant={selectedFilter === 'monthly' ? 'outlined' : 'text'}
      onClick={(e) => handleNonCustomClick(e, 'monthly')}
      sx={{ 
        marginRight: '10px', 
        pointerEvents: selectedFilter === 'monthly' ? 'none' : 'auto', 
        color: selectedFilter === 'monthly' ? grey[600] : 'secondary' 
      }}
    >
      Monthly
    </Button>
  );

  const customFilter = showCustomFilter && (
    <Button
      size="small"
      color="secondary"
      variant={selectedFilter === 'custom' ? 'outlined' : 'text'}
      onClick={handleCustomClick}
      sx={{ marginRight: '10px' }}
    >
      Custom
    </Button>
  );

  return (
    <Grid 
      container 
      item 
      xs={12} 
      justifyContent="center" 
      component="form" 
      onSubmit={(e:any) => e.preventDefault()}
      sx={{ mb: 2 }}
    >
      {dailyFilter}
      {weeklyFilter}
      {monthlyFilter}
      {customFilter}

      {selectedFilter === 'custom' && (
        <>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              value={startDateValue}
              onChange={(newValue) => {
                setStartDateValue(newValue);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />

            <Typography variant="body2" sx={{ margin: '0 10px', alignSelf: 'center' }}>
              to
            </Typography>

            <DatePicker
              label="End Date"
              value={endDateValue}
              onChange={(newValue) => {
                setEndDateValue(newValue);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>

          <TextField
            id="filled-number"
            label="Number of Units"
            type="number"
            variant="outlined"
            value={numberOfUnits}
            onChange={handleTextFieldChange}
            sx={{ minWidth: 50, mx: 1 }}
            size="small"
          />

          <FormControl variant="outlined" size="small" sx={{ minWidth: 160, mx: 1 }}>
            <InputLabel id="custom-simple-select-label">Type of Average</InputLabel>
            <Select
              labelId="custom-simple-select-label"
              id="custom-simple-select"
              value={avgType}
              label="Average Type"
              onChange={(e) => setAvgType(e.target.value)}
            >
              <MenuItem value="month">Month</MenuItem>
              <MenuItem value="week">Week</MenuItem>
              <MenuItem value="day">Day</MenuItem>
            </Select>
          </FormControl>

          <Button
            size="small"
            color="secondary"
            variant="outlined"
            onClick={handleSubmit}
            sx={{ mx: 1 }}
          >
            Submit
          </Button>
        </>
      )}
    </Grid>
  );
};

export default ChartFilterButtons;