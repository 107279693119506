import { Grid, Typography } from '@mui/material'
import FormikInput from '../../../components/formik-input'
import FormikSelect from '../../../components/formik-select'

const BodyBattery = () => {
  return (
    <Grid item={true} xs={12} container={true} alignItems="center" spacing={3}>
      <Grid item={true} xs={2}>
        <Typography variant="subtitle1">Body Battery</Typography>
      </Grid>

      <Grid item={true} xs={2}>
        <FormikInput name="bodyBattery.points" inputLabel="Points" size="small" type="number" />
      </Grid>

      <Grid item={true} xs={1}>
        <Typography variant="subtitle1">lower than</Typography>
      </Grid>

      <Grid item={true} xs={3}>
        <FormikSelect name="bodyBattery.duration" dropdownItems={['monthly average', 'weekly average']} sx={{ marginTop: '1px' }} />
      </Grid>

      <Grid item={true} xs={1}>
        <Typography variant="subtitle1">for</Typography>
      </Grid>

      <Grid item={true} xs={3}>
        <FormikInput name="bodyBattery.daysInRow" inputLabel="Days In Row" size="small" type="number" />
      </Grid>
    </Grid>
  )
}

export default BodyBattery
